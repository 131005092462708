import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import rightArrow from "../../images/right-arrow.svg";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import edit from "../../images/edit.svg";
// import EditModal from "./edit";
import moreIcon from '../../images/moreIcon.svg'
import swal from "sweetalert";
import HRimage from "../imageupload/hr"
import { useSelector } from "react-redux";
import api from '../../apis';
import { v4 as uuidv4 } from "uuid";
import Loader from '../loader';

// import EditModal from "./editpumppayments"

export default function Pump({ item, updatePump }) {

  const authedUser = useSelector(state => state.authedUser);
  const user = authedUser.authedUser;
  const [editshow, seteditShow] = useState(false);
  const [detailshow, setdetailShow] = useState(false);
  const [loader, setLoader] = useState(false);

  const [receiptresponse, setreceiptresponse] = useState([]);
  const [receiptresponsebypump, setreceiptresponsebypump] = useState([]);

  const [imgdata, setImgdata] = useState({});
  const [pumpdata, setPumpdata] = useState({});
  const [detaildata, setDetaildata] = React.useState({});

  const edithandleClose = () => seteditShow(false);

  const edithandleShow = (param) => {
    seteditShow(true);
    setPumpdata(param)
  }

  const detailhandleClose = () => setdetailShow(false);
  const detailhandleShow = (param) => {
    setdetailShow(true);
    setDetaildata(param);
  }

  const [data, setData] = React.useState({
    month: "",
    // amount: "",
    plan: "",
    description: "",
  });

  useEffect(() => {
    if (user.type === "admin") {
      fetchallpayments();
    }
    if (user.type === "pump") {
      fetchallpaymentsbypump();
    }
  }, [])




  const fetchallpayments = async () => {
    try {
      const response = await api.getallpumpreceipt();

      console.log("receiptresponse", response);
      setreceiptresponse(response)

    }
    catch (e) {
      console.log(e);
    }
  }


  const fetchallpaymentsbypump = async () => {
    try {
      const data = {
        pumpid: user._id,
      }

      const response = await api.getallpumpreceiptbypump(data);
      console.log("receiptresponse", response);
      setreceiptresponse(response)
    }
    catch (e) {
      console.log(e);
    }
  }



  const changeapproveStatus = async (item) => {
    try {
      setLoader(true);

      const response = await api.pumpapprove({
        _id: item?.pumpid,
      });

      console.log(response);
      if (!response) {
        throw new Error("Error while updating status");
      }
      else {
        swal({
          title: "Approved",
          icon: "success",
        });

      }
      if (user.type == "admin") {
        fetchallpayments()
      }
      if (user.type == "pump") {
        fetchallpaymentsbypump()
      }
      setLoader(false);
    } catch (e) {
      // alert("Error");\
      setLoader(false);
      swal({
        title: e,
        icon: "error",
      });
    }
  };




  const validator = () => {
    if (!data.month) {
      return "Month is required";
    }
    if (!data.plan) {
      return "Plan is required";
    }
    if (!data.description) {
      return "Desciption is required";
    }
    if (!imgdata.hrimageData) {
      return "Receipt is required";
    }
    return false;
  };





  const handleMonthChange = (event) => {
    setData({ ...data, month: event.target.value });
  };
  const handlePlanChange = (event) => {
    setData({ ...data, plan: event.target.value });
  };
  const handleDescriptionChange = (event) => {
    setData({ ...data, description: event.target.value });
  };


  const editfunc = async () => {
    try {
      if (validator()) {
        throw new Error(validator());
      }
      else {
        var date = new Date();
        date.setDate(date.getDate() + (30 * parseInt(data.month)));
        console.log(date);
        const payloadd = {
          month: data.month,
          amount: parseInt(data.plan) * parseInt(data.month),
          // pumpid: user._id,
          receiptImg: imgdata?.hrimageData,
          receiptFormat: imgdata?.hrimageFormat,
          payment: true,
          end_date: date,
          description: data.description,
          payment_status: "pending",
        };
        console.log("apiayload", payloadd);
        let response = await api.editpumpreceipt(
          {
            user: {
              _id: pumpdata._id,
            },
            update: payloadd,
          }
        )
        console.log(response);
        if (response.success) {
          throw new Error("Bug");
        }
        swal({
          title: "Updated SUCCESSFULLY !",
          // text: "You clicked the button!",
          icon: "success",
        });
        // window.location.reload();
        setData({});
      }

    }
    catch (e) {
      console.log(e);
      swal({
        title: e,
        icon: "warning",
      });
    }
  }



  const rejectfunc = async (item) => {
    try {
      const payloadd = {
        payment_status: "Rejected",
      };
      console.log("apiayload", payloadd);
      let response = await api.rejectpumpreceipt(
        {
          user: {
            _id: item?._id,
          },
          update: payloadd,
        }
      )
      console.log(response);
      if (response.success) {
        throw new Error("Bug");
      }
      swal({
        title: "Rejected SUCCESSFULLY !",
        // text: "You clicked the button!",
        icon: "success",
      });
      // window.location.reload();
      setData({});
    }

    catch (e) {
      console.log(e);
      swal({
        title: e,
        icon: "warning",
      });
    }
  }



  const itemsperpages = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(receiptresponse.length / itemsperpages);

  const itemsInCurrentPage = receiptresponse.slice((currentPage - 1) * itemsperpages, currentPage * itemsperpages);



  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col ">

            <div className="card sortby-box br-10 border-transparent mt-3">
              <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 d-flex flex-column align-items-center justify-content-between">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th className=" pl-4">Name</th>
                      <th className="">Start Date</th>
                      <th className="">Expiry Date</th>
                      <th className="">Status</th>
                      <th className="">Amount</th>
                      <th className="">Months</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      typeof itemsInCurrentPage !== "string" && itemsInCurrentPage
                        .map((item) => (
                          <tr key={uuidv4()} >

                            <td className="d-flex align-items-center">
                              <span className="ml-3">{item?.pumpid?.name}</span>
                            </td>
                            <td>{item?.date}</td>
                            <td>{item.end_date}</td>

                            <td>
                              {item?.pumpid?.canUseAll
                                ? "Approved"
                                : item.payment_status
                              }
                            </td>

                            <td>{item.amount}</td>
                            <td>{item.month}</td>
                            {
                              item?.pumpid?.canUseAll == false && (
                                <td>
                                  <img
                                    onClick={() => edithandleShow(item)}
                                    src={edit} alt="edit" />
                                </td>
                              )
                            }

                            {
                              user.type == "admin" && (
                                <td>

                                  <button class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10"
                                    onClick={() => changeapproveStatus(item)}
                                  >
                                    {item?.pumpid?.canUseAll
                                      ? "Accepted"
                                      : "Accept"}
                                  </button>

                                </td>)
                            }

                            {
                              user.type == "admin" && (
                                <td>
                                  <button class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10"
                                    onClick={() => rejectfunc(item)}
                                  >
                                    Reject
                                  </button>
                                </td>)
                            }

                            <td>

                              <button class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10"
                                onClick={() => detailhandleShow(item)} >Details</button>

                            </td>
                          </tr>
                        ))
                    }


                  </tbody>
                </table>

                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                      <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>
                        Prev
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li key={index + 1} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                        <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                      <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>

              {/* <EditModal open={editModal} setOpen={closeModal} /> */}


              <Modal show={editshow} onHide={edithandleClose} centered={true}>
                <Modal.Body>
                  <div className="modalForm">
                    <h3 className=''>Add New Receipts</h3>
                    <div className="form-group">
                      <label>pump</label>
                      <select onChange={handlePlanChange} className="form-control theme-bg-color border-0 " name="" id="" >
                        <option value="" selected disabled>Select Plan</option>
                        <option value="10000"> Gold </option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label>Months</label>
                      <input type="number" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Months" onChange={handleMonthChange} />
                    </div>
                    <div className="form-group">
                      <label>Description</label>
                      <input onChange={handleDescriptionChange} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" />
                    </div>
                    <div className="form-group">
                      <label>Upload Image</label>
                      <HRimage setImgdata={setImgdata} />

                    </div>

                    <div className='text-center subBtn'>
                      <button class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10" onClick={() => { editfunc() }} >Submit</button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal show={detailshow} onHide={detailhandleClose} centered={true}>
                <Modal.Body>
                  <div className="modalForm">
                    <h3 className=''>Receipt Details</h3>
                    <div className="form-group">
                      <img style={{ height: 300 }} className="receiptimagepump" src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${detaildata?.receiptImg}.${detaildata?.receiptFormat}`} alt="" />
                    </div>
                    <div className="form-group">
                      <label>Description</label>
                      <input value={detaildata?.description} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
                    </div>
                  </div>
                </Modal.Body>
              </Modal>



            </div>



          </div>

          {/* post load / bid column*/}
          {/* <LoadSecondRow /> */}

        </div>

      </div>
      {/* /.container-fluid */}
      {loader && <Loader />}

    </>
  )
}



