import React,{useEffect,useState} from 'react';
import { BrowserRouter as Router, Switch, Route,useParams } from "react-router-dom";
import uploadIcon  from "../../images/uploadIcon.svg";
import aerrowUp from "../../images/aerrowUp.svg";
import correctIcon from "../../images/correctIcon.svg";
import correctIconBlack from "../../images/correctIconBlack.svg";
import correctEnd from "../../images/correctEnd.svg";
import "./AddNewLoad.css";

import { NavLink } from "react-router-dom";



function AddNewLoad() {
 

    return(
      <>
      

      <div className="row mx-0 jc-center addNewLoadPage">
        <div className="col-4">
            <h5 className="loadHeading mb-3">Current Load</h5>
            <LoadBox fromCity="Miami" toCity="Atlanta" fromState="Florida" toState="Georgia" date="May 19, 2020" shipNo="8457-8635" amount="$1,600" />
            <h5 className="loadHeading my-3">future Load</h5>
            <LoadBox fromCity="Miami" toCity="Atlanta" fromState="Florida" toState="Georgia" date="May 19, 2020" shipNo="8457-8635" amount="$1,600" />
            <h5 className="mb-3"></h5>
            <LoadBox fromCity="Miami" toCity="Atlanta" fromState="Florida" toState="Georgia" date="May 19, 2020" shipNo="8457-8635" amount="$1,600" />
            <div className="mr-4">
                <button className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold fs-16 py-3 w-100 mt-3">+ Add New Load</button>
            </div>
        </div>
        <div className="col-8 secondCol">
            <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880.559020490775!2d-79.66297468453615!3d43.78201197911705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b235258f911e1%3A0x829885e34b9d8bee!2sZia%20Dodda%20Crescent%2C%20Brampton%2C%20ON%2C%20Canada!5e0!3m2!1sen!2s!4v1631288430696!5m2!1sen!2s" width="100%" height="400" style={{border:"0"}} allowfullscreen="" loading="lazy"></iframe>
            </div>
            <div className="row mx-0 jc-center mb-3">
                <div className="col-9">
                    <div className="d-flex mapContent jc-sb">
                        <div>
                            <p className="statusText mb-0">Status</p>
                            <p className="status mb-0">On The Way</p>
                        </div>
                        <div>
                            <p className="statusText mb-0">Status</p>
                            <p className="status mb-0">On The Way</p>
                        </div>
                        <div>
                            <p className="statusText mb-0">Status</p>
                            <p className="status mb-0">On The Way</p>
                        </div>
                        <div>
                            <p className="statusText mb-0">Status</p>
                            <p className="status mb-0">On The Way</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-7">
                    <div className="loadDetail">
                        <div className="d-flex jc-sb dateDiv mb-3">
                            <div>
                                <p className="mb-0 dateText text-uppercase">pickup location</p>
                                <p className="date mb-0">Miami, FL</p>
                            </div>
                            <div>
                                <p className="mb-0 dateText text-uppercase">Delivery location</p>
                                <p className="date mb-0">Atlanta, FL</p>
                            </div>
                        </div>
                        <div className="d-flex jc-sb dateDiv mb-3">
                            <div>
                                <p className="mb-0 dateText text-uppercase">pickup Time</p>
                                <p className="date mb-0">Jan 14, 2021 At 10:30AM (EST)</p>
                            </div>
                            <div>
                                <p className="mb-0 dateText text-uppercase">Delivery Time</p>
                                <p className="date mb-0">Jan 15, 2021 At 10:30AM (EST)</p>
                            </div>
                        </div>
                        <div className="d-flex jc-sb dateDiv mb-3">
                            <div>
                                <p className="mb-0 dateText text-uppercase">shipment number</p>
                                <p className="date mb-0">8174-2802</p>
                            </div>
                        </div>
                        <div className="d-flex jc-sb dateDiv mb-3">
                            <div>
                                <p className="mb-0 dateText text-uppercase">container type</p>
                                <p className="date mb-0">Dry Van</p>
                            </div>
                            <div>
                                <p className="mb-0 dateText text-uppercase">container weight</p>
                                <p className="date mb-0">15,000 Lbs</p>
                            </div>
                            <div>
                                <p className="mb-0 dateText text-uppercase">permit required</p>
                                <p className="date mb-0">No</p>
                            </div>
                        </div>
                        <div className="d-flex   dateDiv">
                            <div className="loadPrice">
                                <p className="mb-0 dateText text-uppercase">load price</p>
                                <p className="fs-22 mb-0">$1,600</p>
                            </div>
                            <div>
                                <p className="mb-0 dateText text-uppercase">Dispatch fee <span>(10%)</span></p>
                                <p className=" mb-0 fs-22">$1,600</p>
                            </div>
                            <div>
                              
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 orderAccept-col">
                    <div className="p-2 py-4 d-flex orderAccept-col-sm">
                        <div className="mr-3 text-center">
                            <img src={correctIcon} alt="" className="" />
                            <div className="lineh20"></div>
                            <img src={correctIcon} alt="" className=""/>
                            <div className="lineh25"></div>
                            <img src={correctIconBlack} alt="" className="" />
                            <div className="lineh50"></div>
                            <img src={correctEnd} alt="" />
                        </div>
                        <div>
                            <h6 className="mb-5" >Order Accepted</h6>
                            <h6 className="mb-5">Preparing Order</h6>
                            <h5 className="">Ready To Collect</h5>
                            <p>Your order was collected and it's waiting for you at the pickup spot</p>
                            <h6>Order Collected</h6>
                        </div>
                    </div>
                </div>
            </div>


        
        </div>
      </div>
         
      </>
    )
  }
  const LoadBox=(props)=>{
      return(
        <div className="LoadInfo mr-4">
            <div className="d-flex jc-sb destinationDiv">
                <div>
                    <p className="mb-0 city">{props.fromCity}</p>
                    <p className="state mb-0">{props.fromState}</p>
                </div>
                <div>
                    <p className="to text-center">to</p>
                    <img src={aerrowUp} alt="" />
                </div>
                <div>
                    <p className="mb-0 city">{props.toCity}</p>
                    <p className="state mb-0">{props.toState}</p>
                </div>
            </div>
            <div className="d-flex jc-sb dateDiv">
            <div>
                <p className="mb-0 dateText text-uppercase">Date</p>
                <p className="date mb-0">{props.date}</p>
            </div>
            <div>
                <p className="mb-0 dateText text-uppercase">Shipment No</p>
                <p className="date mb-0">{props.shipNo}</p>
            </div>
        </div>
            <div className="d-flex jc-sb amountDiv">
            <div>
                <p className="completed mb-0 text-uppercase">Completed</p>
            </div>
            <div>
                <span className="dateText mr-1">Load Rate</span>
                <span className="date">{props.amount}</span>
            </div>
        </div>
        </div>

      )
  }

  export default AddNewLoad