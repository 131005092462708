import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux";
import swal from "sweetalert";
import api from "../../apis";
import SyncProducts from './syunc_products'
import BankDetails from './bankdetails'
import edit from "../../images/delete.svg";
import './accounting.css'
import Loader from '../loader';


export default function Nozel({ ADSshow, ADShandleClose }) {

  const user = useSelector(state => state.authedUser.authedUser);
  console.log(user);

  const [productshow, setProductshow] = useState(false)


  const [bankshow, setBankshow] = useState(false)

  const bankhandleClose = () => {
    setBankshow(false);
  }

  const synchandleClose = () => {
    setProductshow(false);
  }

  const productmodule = () => {
    ADShandleClose()
    setProductshow(true)
  }



  const [formValues, setFormValues] = useState([{ qty: "", amount: "", product: "" }])
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchAllProduct();
  }, [])


  const fetchAllProduct = async () => {
    try {
      // setLoader(true);

      if (user.type == "pump") {
        const response = await api.readproducts({ pump: user?._id })

        if (response.success) {
          setProducts(response.message);
        }
      }
      // setLoader(false);
    } catch (e) {
      console.log(e);
      // setLoader(false);
    }
  };



  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;

    setFormValues(newFormValues);
  }

  let addFormFields = () => {
    setFormValues([...formValues, { qty: "", amount: "", product: "" }])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
  }

  let handleSubmit = async (event) => {
    // alert(JSON.stringify(formValues));
    try {
      event.preventDefault();
      const payload = {
        formValues
      }
      console.log(payload);
    }
    catch (e) {
      console.log(e);

    }
  }


  return (
    <>

      <Modal show={ADSshow} onHide={ADShandleClose} centered={true}>
        <Modal.Body>


          <div className="modalForm br-10 d-flex methodcontainer" >
            <h3 className=''>Add Product Sale</h3>
            <button class=" w-25 btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 ms-auto"
              onClick={() => addFormFields()}
            >Add</button>

            <form onSubmit={handleSubmit}>
              {formValues.map((element, index) => (
                <div className="form-inline" key={index}>
                  <div className='NozelContainer theme-bg-color'>
                    {
                      index ?
                        <img onClick={() => removeFormFields(index)} className='delete' src={edit} alt="" />
                        : null
                    }
                    <br />
                    <div>
                      <select onChange={e => handleChange(index, e)} className="form-control border-0 selectionProduct" name="product" id="" >
                        <option value="" selected disabled> Select Product </option>
                        {typeof products !== "string" &&
                          products.map((item) =>
                          (
                            <option value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                      <input onChange={e => handleChange(index, e)} type="number" className="input1 form-control border-0 small" id="edit-pump-name" aria-describedby="emailHelp" name='qty' placeholder="Enter QTY" />
                      <input onChange={e => handleChange(index, e)} type="number" className="input2 form-control border-0 small" id="edit-pump-name" aria-describedby="emailHelp" name='amount' placeholder="Enter Amount" />

                    </div>
                  </div>
                </div>
              ))}


              <button type='submit' class="w-25 mt-5 btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10"
              // onClick={() => productmodule()}
              >Save</button>


            </form>
          </div>
        </Modal.Body>
      </Modal>






      <SyncProducts productshow={productshow} setProductshow={setProductshow} synchandleClose={synchandleClose} />

      <BankDetails bankshow={bankshow} bankhandleClose={bankhandleClose} />

    </>
  );
}