import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap"
import { ToastContainer, toast } from 'react-toastify';
import { NavLink } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

import pumpifylogo from '../../../images/logo.png'
import pumpifylogoDark from '../../../images/logo.png'
import phone from '../../../images/phone.svg';


function SiteNav() {
    const [showDarkLogo,setShowDarkLogo]= useState(false)
    const [show, setShow] = useState(false);

    const [contactForm, setContactForm] = useState({   
        name:"",
        email:"",
        phone:"",
        message:"",
    });
       // notification setting
       const notify = (response,types) => 
       types(response, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
       });

    const handleClose = () => setShow(false);
    const handleShow = (text) => {
        setShow(true)
    }
   

    const handleContactF =(e) => {
        setContactForm({...contactForm,[e.target.name]:e.target.value})
    }

    const submitContactForm =(e) => {
        e.preventDefault();
        console.log(contactForm);
        var valid = /^!*(\d!*){10,}$/;
        var validM = valid.test(contactForm.phone)
        if(contactForm.phone && contactForm.phone.includes(' ') ) return notify("Spaces are not allowed in phone number",toast.error);
        if(contactForm.phone && (!validM || contactForm.phone.length < 10)) return notify("Phone must contain atleast 10 digits",toast.error);
        
        if(!contactForm.email){
            return notify("email is required",toast.error)
        }
        if(!contactForm.message){
            return notify("message is required",toast.error)
        }
        const response = axios.post('https://api.pumpify.co/contact-us-mailer', contactForm)
        .then((res)=>{
            console.log(res);
            alert("Thanks, we will reach you soon.")
            handleClose(false)
            setContactForm({   
                name:"",
                email:"",
                phone:"",
                message:"",
            });
        })
        .catch((err)=>{
            console.log(err);
            notify("Something went wrong",toast.error)
            handleClose(false)
        })
    }

 

    return (
        <>
         {/* downNav */}
        <nav class="navbar sitenavbar navbar-expand-lg navbar-dark " id="downNav">
            <div class="container">
                <NavLink class="navbar-brand ms-5 text-dark PUMPIFY" to="/">
                    {showDarkLogo?
                    <img src={pumpifylogoDark} alt="" className="pumplogonav" />
                    :
                    <img src={pumpifylogo} alt="" className="pumplogonav" />
                    }
                </NavLink>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon" id="toggleIco"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mx-auto ai-center mb-2 mb-lg-0  navcont">
                        <li class="nav-item help">
                            <NavLink to="/account">Sign Up</NavLink>
                        </li>
                        <li class="nav-item help">
                            {/* <button class="btn btn-outline-primary" type="submit">Login </button> */}
                            <NavLink to="/login">Login</NavLink>
                        </li>
                        <li class="nav-item help">
                            {/* <button class="btn btn-outline-primary" type="submit">HR verification</button> */}
                            <NavLink to="/hr">HR verification</NavLink>
                        </li>
                        <li class="nav-item docs">
                            <a class="contactNav dNone-lg" onClick={()=>handleShow("Contact Us")}>Contact Us</a>
                        </li>

                    </ul>
                </div>
                <div className="dFlex-lg">
                    <a class="contactNav " onClick={()=>handleShow("Contact Us")}>Contact Us</a>
                </div>
            </div>
        </nav>
         {/* Contact Modal */}
         <Modal show={show} onHide={handleClose} className="contactMM" centered={true}>
                <Modal.Body className='contactModal' >
                    <div className="w-100">
                        <div className="heading">
                        <h1>Contact Us</h1>
                        <i class="fa fa-times" onClick={handleClose}></i>
                        </div>
                        <div className="mainContent">
                        <div className="row mx-0">
                            <div className="col-md-6">
                            <div className='mb-5'>
                                <p className="contact">
                                You’ve any question? <br />
                                feel free to contact with us.
                                </p>
                                <div className="socialMain">
                                <a href="https://www.linkedin.com/company/dappomatics" target="_blank" > <i class="fab fa-2x fa-linkedin fb"></i></a>
                                <a href="https://www.facebook.com/dappomatics" target="_blank" > <i class="fab fa-2x fa-facebook-square fb"></i></a>
                                <a href="https://twitter.com/dappomatics" target="_blank" > <i class="fab fa-2x fa-twitter-square twitter"></i></a>
                                <a href="https://www.instagram.com/dappomatics" target="_blank" > <i class="fab fa-2x fa-instagram git"></i></a>
                                </div>
                                <div className='contentDiv'>
                                <div className="imgDiv">
                                    <img src={phone} alt="phone" />
                                </div>
                                <div className='contactDiv'>
                                    <p>Call us for imiditate support on this number</p>
                                    <a href="tel:+ 92 335 2491559"  >+92 335 2491559</a>
                                </div>
                                </div>
                                <div className='contentDiv'>
                                <div className="imgDiv">
                                    {/* <img src={send} alt="phone" /> */}
                                    <i class="fas fa-2x fa-envelope p-0"></i>
                                </div>
                                <div className='contactDiv'>
                                    <p>Send us email for any kind of inquiry</p>
                                    <a href="mailTo:contact@pumpify.com" target="_blank" >contact@pumpify.com</a>
                                </div>
                                </div>
                            
                            </div>
                            </div>
                            <div className="col-md-6">
                            <form onSubmit={(e)=>submitContactForm(e)}>
                            <div className="form-group">
                                {/* <label htmlFor="exampleInputPassword1">Password</label> */}
                                <input onChange={(e)=>handleContactF(e)} name="name" value={contactForm?.name} type="text" className="form-control theme-bg-color" id="exampleInputPassword1" placeholder="Name" />
                            </div>
                            <div className="form-group">
                                {/* <label htmlFor="exampleInputEmail1">Email address</label> */}
                                <input onChange={(e)=>handleContactF(e)} name="email" value={contactForm?.email} type="email" className="form-control theme-bg-color" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Your email address*" />
                            </div>
                        
                            <div className="form-group w-100">
                                <input onChange={(e)=>handleContactF(e)} name="phone" value={contactForm?.phone} type="text" className="form-control theme-bg-color" id="exampleInputtext1" placeholder="Phone" />
                            </div>
                            
                            <div className="form-group">
                                <textarea onChange={(e)=>handleContactF(e)} name="message" value={contactForm?.message} type="text" className="form-control theme-bg-color" id="exampleInputPassword1" placeholder="Write message here*" rows={4} />
                            </div>
                            <div className="text-center mt-4">
                                <button type="submit" className="btn btn-primary sendBtn">Submit</button>
                            </div>
                            </form>
                            </div>
                        </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
          <ToastContainer pauseOnFocusLoss={false} style={{ width: "max-content" }}/>      

        </>
    );
}

export default SiteNav;