import React from "react";
import Modal from 'react-bootstrap/Modal';


export default function VehicleDetailModal({ show, setShow, detailData }){
  console.log(detailData)
  return(
    <Modal show={show} onHide={() => setShow(false)} centered={true}>
      <Modal.Body>
        <div className="modalForm">
          <h3 className=''>Vehicle Details</h3>
          <div className="form-group">
            <label>Vehicle Owner</label>
            <input value={detailData?.name} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>
          <div className="form-group">
            <label>Email address</label>
            <input value={detailData?.email} type="email" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
          </div>
          <div className="form-group">
            <label>Vehicle Number</label>
            <input value={detailData?.vehicle_number} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input value={detailData?.phone} type="number" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>
          <div className="form-group">
            <label>pump</label>
            <input value={detailData?.pump?.name} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>
          <div className="form-group">
            <label>Company</label>
            <input value={detailData?.company?.name} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>
          <div className="form-group">
            <label>Limit</label>
            <input value={detailData?.limit} type="number" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>

        </div>
      </Modal.Body>
    </Modal>
  );
}