import * as ActionTypes from '../ActionTypes';

export const AuthedUser = (state = {
    isLoading: false,
    errMess: null,
    authedUser: {}
}, action) => {
        switch(action.type) {
            case ActionTypes.LOGGED_IN:
                localStorage.setItem("token", action.payload.token);
                localStorage.setItem("email", action.payload.email);
                localStorage.setItem("type", action.payload.type);
                return {...state, isLoading: false, errMess: null, authedUser: action.payload}
            case ActionTypes.UPDATE_USER:
                return {...state, isLoading: false, errMess: null, authedUser: action.payload}
            case ActionTypes.LOGGED_OUT:
                localStorage.removeItem("token");
                localStorage.removeItem("type");
                localStorage.removeItem("email");
              return {...state, isLoading: false, errMess: null, authedUser: {}}
            default:
                return state;
        }
};