import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Loader from "../loader";
import swal from "sweetalert";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import api from '../../apis';
// import register_staff from "../../api/users/register_staff";
import FormControl from "@material-ui/core/FormControl";
// import get_pumps from "../../api/users/get_pumps";
// import get_permission from "../../api/permission/read_permission";
import helpers from "../../helpers/helpers";

function Add({ open, setOpen, fetchData }) {
  const [loader, setLoader] = useState(false);
  const user = useSelector((state) => state.authedUser.authedUser);
  const [pumps, setPumps] = React.useState([]);
  const [data, setData] = React.useState({
    name: "",
    address: "",
    // password: "",
    email: "",
    pump: "",
    phone: "",
    permissions: [],
  });

  useEffect(() => {
    if (user.type === "pump") {
      setData({
        ...data,
        pump: user?._id,
      });
    }

    fetchPumps();
  }, [open]);

  const addStaff = async () => {
    try {
      if (user.type == "admin" || (user.type == "pump" && user.canUseAll)) {

        setLoader(true);
        if (validator()) {
          throw new Error(validator());
        } else {
          if (localStorage.getItem("token")) {
            var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
            var onetimepass = '';
            for (var i = 0; i < 40; i++) {
              onetimepass += characters.charAt(Math.floor(Math.random() * characters.length))
            }
        
            let response = await api.register_staff({
              phone: '',
              type: "staff",
              email: data.email,
              name: data.name,
              address: data.address,
              pump: data.pump,
              billing_expiry: null,
              cnic: data.cnic,
              status: true,
              phone: data.phone,
              permissions: [],
              otpass: onetimepass,
              companystaff: true,          
            });

            if (!response.success) {
              throw new Error(response.message);
            }

            setData({});
            fetchData();
            handleClose();
            // alert("Registeration Successfull.");
            swal({
              title: "ADDED SUCCESSFULLY !",
              // text: "You clicked the button!",
              icon: "success",
            });
          }
        }
        setLoader(false);
      }
      else {
        swal({
          title: "You are blocked due to some readon contact admin",

          icon: "warning",
        });
      }
    } catch (e) {
      console.log(e);
      // alert(e);
      swal({
        title: e,

        icon: "warning",
      });
      setLoader(false);
    }
  };

  const validator = () => {
    if (!data.name) {
      return "Name is required";
    }
    if (!data.email) {
      return "Email is required";
    }
    if (data.email && !helpers.isValidEmail(data.email)) {
      return "Email is not correctly formatted";
    }
    if (!data.address) {
      return "Address is required";
    }
    if (!data.phone) {
      return "Phone is required";
    }
    if (data.phone && isNaN(data.phone)) {
      return "Phone is not correctly formatted";
    }
    let ph = data.phone.toString();

    if (ph[0] !== "0" || ph[1] !== "3") {
      return "Phone number must start from 03";
    }

    if (data.phone.length !== 11) {
      return "Phone is not correctly formatted";
    }
    if (!data.pump) {
      return "Pump is required";
    }
    // if (!data.password) {
    //   return "Password is required";
    // }
    // if (data.password.length < 6) {
    //   return "Password must be greater than 6 characters";
    // }
    if (!data.cnic) {
      return "CNIC is required";
    }
    if (data.cnic && isNaN(data.cnic)) {
      return "CNIC is not correctly formatted";
    }
    if (data.cnic.length !== 13) {
      return "CNIC is not correctly formatted";
    }

    return false;
  };
  const fetchPumps = async () => {
    try {
      setLoader(true);
      const response = await api.getpumpsforcompany({ type: "pump" });

      setPumps(response.message);
      setLoader(false);
    } catch (e) {
      // alert(e);
      swal({
        title: e,

        icon: "warning",
      });
      setLoader(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePumpChange = (event) => {
    setData({ ...data, pump: event.target.value });
  };

  const handleNameChange = (event) => {
    setData({ ...data, name: event.target.value });
  };

  const handleAddressChange = (event) => {
    setData({ ...data, address: event.target.value });
  };

  // const handlePasswordChange = (event) => {
  //   setData({ ...data, password: event.target.value });
  // };

  const handleEmailChange = (event) => {
    setData({ ...data, email: event.target.value });
  };

  const handlePhoneChange = (event) => {
    setData({ ...data, phone: event.target.value });
  };

  const handleCNICChange = (event) => {
    setData({ ...data, cnic: event.target.value });
  };

  const handleCheckChange = (event, name) => {
    // setData({ ...data, permission: event.target.value });
    data.permissions.push(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Staff Details</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          value={data.name}
          onChange={handleNameChange}
        />
        <TextField
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          value={data.email}
          onChange={handleEmailChange}
        />
        <TextField
          margin="dense"
          label="Address"
          type="text"
          fullWidth
          value={data.address}
          onChange={handleAddressChange}
        />
        <TextField
          margin="dense"
          label="Phone"
          type="number"
          fullWidth
          value={data.phone}
          onChange={handlePhoneChange}
        />
        {/* <TextField
          margin="dense"
          label="Password"
          type="password"
          fullWidth
          value={data.password}
          onChange={handlePasswordChange}
        /> */}
        {/* <Tippy content="enter without dash"> */}

          <TextField
            margin="dense"
            label="Cnic "
            type="number"
            fullWidth
            value={data.cnic}
            onChange={handleCNICChange}
          />
        {/* </Tippy> */}

        {user.type !== "pump" && (
          <FormControl style={{ width: "100%", marginTop: 5 }}>
            <InputLabel id="demo-simple-select-helper-label">Pumps</InputLabel>
            <Select
              style={{ width: "100%" }}
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              value={data.pump}
              onChange={handlePumpChange}
            >
              {typeof pumps !== "string" &&
                pumps.map((item) => (
                  <MenuItem value={item._id}>
                    {item.name}({item.email})
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        <FormGroup className={"mt-3"}>
          {/* <label>Permissions</label>
          {permission.map((item) => (
            <FormControlLabel
              control={<Checkbox />}
              label={item.title}
              value={item._id}
              onChange={(e) => handleCheckChange(e)}
            />
          ))} */}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={addStaff} color="secondary">
          Save
        </Button>
      </DialogActions>
      {loader && <Loader />}
    </Dialog>
  );
}

export default Add;
