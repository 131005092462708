import  AppRouter from "../../route/route";
import Sidebar from '../global/sidebar/sidebar';
import Navbar from '../global/navbar/navbar';

export const AdminPages =()=> {
    console.log("bccc");
return(
    <>
        <div id="wrapper">
            <Sidebar />
              <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                  <Navbar />
                {/* Begin Page Content */}
                  <AppRouter />
            {/* End of Main Content */}
              </div>
          </div>
        </div>
        {/* Scroll to Top Button*/}
        <a className="scroll-to-top rounded" href="#">
          <i className="fas fa-angle-up" />
        </a>
    </>
)
}