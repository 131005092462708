import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector } from "react-redux";
import api from '../../apis';
import swal from "sweetalert";
import helpers from "../../helpers/helpers";

function Edit({ open, setOpen, defaultData, fetchData }) {
  const user = useSelector(state => state.authedUser.authedUser);
  const [loader, setLoader] = useState(false);
  const [data, setData] = React.useState({
    name: defaultData?.name,
    address: defaultData?.address,
    email: defaultData?.email,
    phone: defaultData?.phone,
    cnic: defaultData?.cnic
  });

  const editStaff = async () => {
    try {
      if (user.type == "admin" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true)
        if (validator()) {
          throw new Error(validator());
        } else {
          if (localStorage.getItem("token")) {
            let response = await api.update_staff({user: {_id: defaultData._id}, update: {...data, _id: defaultData._id}});

            if (!response.success) {
              throw new Error(response.message);
            }

            await fetchData();
            handleClose();
            // alert("Update Successfull.");
            swal({
              title: "UPDATED SUCCESSFULLY !",
              // text: "You clicked the button!",
              icon: "success",
            });
          }
        }
        setLoader(false)
      }
      else {
        swal({
          title: "You are blocked due to some reason contact admin",
          icon: "warning",
        });
      }

    } catch (e) {
      console.log(e);
      // alert(e);
      swal({
        title: e,
        icon: "warning",
      });
      setLoader(false)
    }
  };

  const validator = () => {
    if (!data.name) {
      return "Name is required";
    }
    if (!data.email) {
      return "Email is required";
    }
    if (data.email && !helpers.isValidEmail(data.email)) {
      return "Email is not correctly formatted";
    }
    if (!data.address) {
      return "Address is required";
    }
    if (!data.phone) {
      return "Phone is required";
    }
    let ph = data.phone.toString();

    if (ph[0] !== "0" || ph[1] !== "3") {
      return "Phone number must start from 03";
    }

    if (data.phone.length !== 11) {
      return "Phone is not correctly formatted";
    }
    if (data.phone && isNaN(data.phone)) {
      return "Phone is not correctly formatted";
    }

    if (!data.cnic) {
      return "CNIC is required";
    }
    if (data.cnic && isNaN(data.cnic)) {
      return "CNIC is not correctly formatted";
    }
    if (data.cnic.length !== 13) {
      return "CNIC is not correctly formatted";
    }
    return false;
  };

  const handleClose = async () => {
    setOpen(false);
  };

  const handleNameChange = (event) => {
    setData({ ...data, name: event.target.value });
  };

  const handleAddressChange = (event) => {
    setData({ ...data, address: event.target.value });
  };

  const handleEmailChange = (event) => {
    setData({ ...data, email: event.target.value });
  };

  const handlePhoneChange = (event) => {
    setData({ ...data, phone: event.target.value });
  };


  const handleCNICChange = (event) => {
    setData({ ...data, cnic: event.target.value });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Staff Details</DialogTitle>
      <DialogContent>
      <TextField
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          value={data.name}
          onChange={handleNameChange}
        />
        <TextField
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          value={data.email}
          onChange={handleEmailChange}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          margin="dense"
          label="Address"
          type="text"
          fullWidth
          value={data.address}
          onChange={handleAddressChange}
        />
        <TextField
          margin="dense"
          label="Phone"
          type="number"
          fullWidth
          value={data.phone}
          onChange={handlePhoneChange}
        />
        <TextField
          margin="dense"
          label="Cnic (without dashes)"
          type="number"
          fullWidth
          value={data.cnic}
          onChange={handleCNICChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Close
        </Button>
        <Button onClick={() => editStaff()} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Edit;
