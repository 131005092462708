import { useState } from "react";
import { Modal, Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom";  

import SiteNav from "../siteNavbar/siteNavbar";
import { Footer } from "../footer/footer";
import modalImg from '../../../images/modalImg.png';
import chatUser from '../../../images/chatUser.jpg';
import Loader from '../../loader';
import api from '../../../apis';
import swal from "sweetalert";

export function HrVerification() {
    const navigate = useNavigate()
    const [showData,setShowData]= useState(false)
    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState([]);

    const phoneSubmit = async (e) => {
        e.preventDefault();
        let phone = document.getElementById("phone").value;
        try {
            setLoader(true);
            if (!phone) {
                swal({
                    title: "Please enter phone !",
                    icon: "warning",
                });
                setLoader(false);
                return;
            }
            const res = await api.getHRPhone({ phone });
            // setResponsedata(res.data)
            console.log(res);
            console.log(res?.data?.length);
            if (res?.data?.length == 0) {
                setUserDetails([]);
                swal({
                    title: "No data found",
                    icon: "warning",
                });
            }
            else{
                setUserDetails(res.data);
            }
            setLoader(false);
        } catch (e) {
            //alert("error while fetching details.");
            console.log(e);
            swal({
              title: e,
              // text: "You clicked the button!",
              icon: "error",
            });
            setLoader(false);
        }
    }

    const cnicSubmit = async (e) => {
        e.preventDefault();
        let cnic = document.getElementById("cnic").value;
        let data = {
            cnic: cnic
        };
        try {
            setLoader(true);
            if (!data.cnic) {
                swal({
                    title: "Please enter CNIC !",
                    icon: "warning",
                });
                setLoader(false);
                return;
            }
      
            if (data.cnic && isNaN(data.cnic)) {
                //alert("Please enter CNIC in correct format");
                swal({
                    title: "Please enter CNIC in correct format",
                    // text: "You clicked the button!",
                    icon: "warning",
                });
                setLoader(false);
                return;
            }
            if (data.cnic.length !== 13) {
                //alert("Please enter CNIC in correct format");
                swal({
                    title: "Please enter CNIC in correct format. Err: Length < 13",
                    // text: "You clicked the button!",
                    icon: "warning",
                });
                setLoader(false);
                return;
            }
      
            const res = await api.getHR({ cnic: data.cnic });
            // setResponsedata(res.data)
            console.log(res);
            console.log(res.data.length);
            if (res.data.length == 0) {
                setUserDetails([]);
                swal({
                    title: "No data found",
                    icon: "warning",
                });
            }
            else{
                setUserDetails(res.data);
            }
            setLoader(false);
        } catch (e) {
            //alert("error while fetching details.");
      
            swal({
              title: e,
              // text: "You clicked the button!",
              icon: "error",
            });
            setLoader(false);
        }
    }

    const getBadge = (userDetails) => {
        if (!userDetails.isApproved){
            return <span class="badge badge-warning mr-1">Unverified</span>
        }
        else if (userDetails.resolved){
            return <span class="badge badge-success mr-1">Resolved</span>
        }
        else if (userDetails.requestresolve){
            return <span class="badge badge-warning mr-1">Resolve in process</span>
        }
        else{
            return <span class="badge badge-danger">Blacklisted</span>
        }
    }

    return(
        <div className="loginPage">
            <SiteNav />

            <div className='contactModal mt-3' >
                    <div className="container">
                        <div className="row ai-center">
                            <div className="col-lg-7">
                                {userDetails.map((userDetail, i) => {
                                    return(
                                        <div className="mainContent userHrDetail mb-3" key={userDetail._id}>
                                            <div className="logoDiv">
                                                <img src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${userDetail.profilePic}.${userDetail.profilePicFormat}`} alt="" />
                                            </div>
                                            <div className="content">
                                            <div className="row py-2">
                                                    <div className="col-6">
                                                        <div className="name">Status</div>
                                                    </div>
                                                    <div className="col-6">
                                                        {getBadge(userDetail)}
                                                    </div>
                                            </div>
                                            <div className="row py-2">
                                                    <div className="col-6">
                                                        <div className="name">Name</div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="value">{userDetail.name}</div>
                                                    </div>
                                            </div>
                                            <div className="row py-2">
                                                    <div className="col-6">
                                                        <div className="name">CNIC</div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="value">{userDetail?.cnic}</div>
                                                    </div>
                                            </div>
                                            <div className="row py-2">
                                                    <div className="col-6">
                                                        <div className="name">Phone</div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="value">{userDetail?.phone}</div>
                                                    </div>
                                            </div>
                                            <div className="row py-2">
                                                    <div className="col-6">
                                                        <div className="name">Pump</div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="value">{userDetail?.pump?.name}</div>
                                                    </div>
                                            </div>
                                            </div>
                                        </div>
    
                                    );
                                })}
                                {userDetails.length === 0 ? (
                                <div className="modalImg">
                                    <img className="img-fluid" src={modalImg} alt="pump" />
                                </div>
                                ) : (<></>)}
                            </div>
                            <div className="col-lg-5">
                                <div className="mainContent">
                                <div className="heading text-center p-0">
                                    <h1>HR System</h1>
                                    <p className="mb-4">Note: Please enter cnic without dashes or Phone No, Dashes are not allowed.</p>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-md-12">
                                    <form>
                                    <div className="d-flex">
                                        <div className="form-group w-100 mb-2 mr-2">
                                            <label htmlFor="cnic">CNIC</label>
                                            <input name="name" id="cnic" type="text" className="form-control theme-bg-color " placeholder="CNIC No. (without dashes)" />
                                            <div className="text-center mt-4">
                                                <button className="btn sendBtn" onClick={cnicSubmit}>Search</button>
                                            </div>
                                        </div>
                                        <div className="form-group w-100 mb-2">
                                            <label htmlFor="phone">Phone No</label>
                                            <input name="name" id="phone" type="text" className="form-control theme-bg-color " placeholder="Contact Number" />
                                            <div className="text-center mt-4">
                                                <button className="btn sendBtn" onClick={phoneSubmit}>Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    </form>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <Footer />

            {/* <Modal show={show} onHide={handleClose} centered={true} >
                <Modal.Body className='contactModal nodataModal' >
                    <div className="heading text-center w-100">
                    <span className="far fa-times-circle crossIC"></span>
                        <h1>No Data Found</h1>
                        <i class="fa fa-times" onClick={handleClose}></i>
                    </div>
                </Modal.Body>
            </Modal> */}
        </div>
    )
}