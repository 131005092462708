import React,{useEffect,useState} from 'react';
import { BrowserRouter as Router, Routes, Route,NavLink,useParams, useMatch, useLocation, useResolvedPath } from "react-router-dom";
import plus from "../../images/plus.svg";
import "./AssignNewLoad.css";

import AssignLoadForm from '../AssignLoadForm/AssignLoadForm';
import AddNewLoad from '../AddNewLoad/AddNewLoad';
import Chat from '../Chat/Chat';


function AssignNewLoad() {
  let { pathname: path } = useLocation();
  const url = useResolvedPath("").pathname;

  let { topicId } = useParams();
  let [state,setState]=useState(false)
  let matchRoute;
  console.log(path);
  console.log(window.location.pathname);
    return(
      <>
                  {/* Begin Page Content */}
                    <div className="container-fluid assignNewLoadPage">
                     {/* Content Row */}
                      <div className="row jc-center">
                       <div className="">
                           <p className="abcTr color-dark mb-0 mt-3">ABC Trucking, LLC</p>
                           <div className="usdot my-3">
                               <span className="mr-5">USDOT: 12345678</span>
                               <span>MC: 12345678</span>
                           </div>
                           <div className="text-center py-3 assignLoadLinkDiv">
                               <NavLink to={`${url}/assignLoadForm`} activeClassName="active" className="assignLoadLink mx-4">Load</NavLink>
                               <NavLink to={`${url}/addNewLoad`} activeClassName="active" className="assignLoadLink mx-4">History</NavLink>
                               <NavLink to={`${url}/chat`} activeClassName="active" className="assignLoadLink mx-4">chat</NavLink>
                               <a href="#" className="assignLoadLink mx-4">Financial</a>
                               <a href="#" className="assignLoadLink mx-4">Driver Profile</a>
                           </div>
                       </div>
                      </div>

                           <Routes>
                           <Route exact path={path}>
                            <AddBtn />
                           </Route>
                           {window.location.pathname == "/assignNewLoad/assignLoadForm"?
                             <Route path={`${path}/:topicId`} component={AssignLoadForm} />
                           :
                           ""
                           }
                           {window.location.pathname == "/assignNewLoad/addNewLoad"?
                             <Route path={`${path}/:topicId`} component={AddNewLoad} />
                           :
                           ""
                           }
                           {window.location.pathname == "/assignNewLoad/chat"?
                             <Route path={`${path}/:topicId`} component={Chat} />
                           :
                           ""
                           }
                           
                           </Routes>
       

                     
                      
                      
              
                  </div>
                  {/* /.container-fluid */}
         
      </>
    )
  }
  const AddBtn=()=>{
    let { path, url } = useMatch();

    return(
      <div className="addNewLoadHeadingDiv">
          <p className="addNewLoadHeading mb-4">Assign New Load</p>
          <NavLink to={`${url}/assignLoadForm`} className="assignLoadBtn"><img src={plus} alt="" /></NavLink>
      </div>
    )
  }


  export default AssignNewLoad