import React, { useEffect, useState } from 'react';
import api from "../../apis/index";
import swal from "sweetalert";
import { useSelector } from "react-redux";

function Pump() {

  const authedUser = useSelector(state => state.authedUser);
  const user = authedUser.authedUser;

  const [loader, setLoader] = useState(false);
  const [pump, setPumps] = useState([]);
  const [company, setCompany] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  const [data, setData] = useState(
    {
      pumpid: "",
      company: "",
      month: "01",
    }
  );

  const [datavehicle, setDatavehicle] = useState(
    {
      email: "",
      month: "01",
    }
  );

  useEffect(() => {
    if (user.type === "admin"){
      fetchAllPumps();
    }
    else if (user.type === "pump") {
      setPumps([user]);
      fetchAllCompany(user._id);
    }
    else if (user.type === "company") {
      setPumps([user.pump]);
      console.log("user", [user]);
      setCompany([user]);
      fetchAllVehicle(user._id);
    }
    else if (user.type === "vehicle"){
      setPumps([user.pump]);
      setCompany([user.company]);
      setVehicles([user]);
    }
  }, [data?.pumpid]);





  useEffect(() => {
    if (data.pumpid){
      fetchAllCompany(data.pumpid);
      setVehicles([]);
    }
  }, [data.pumpid]);

  useEffect(() => {
    if (data.company){
      fetchAllVehicle(data.company);
    }
  }, [data.company]);

  const fetchAllPumps = async () => {
    try {
      setLoader(true);
      const response = await api.getpumpsforcompany({
        type: "pump",
      })
      console.log("pumps", response);
      if (response.message.length > 0 && typeof response.message !== "string"){
        setPumps(response.message);
        setData({
          ...data,
          pumpid: response.message[0]._id
        });
      }
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };
  const fetchAllCompany = async (pumpid) => {
    try {
      setLoader(true)
      const response = await api.getcompanies({
        type: "company",
        pump: data.pumpid,
      })
      console.log("company", response);
      if (response.message.length > 0 && typeof response.message !== "string"){
        setCompany(response.message);
        setData({
          ...data,
          company: response.message[0]._id
        });
      }
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };
  const fetchAllVehicle = async (company) => {
    try {
      setLoader(true);
      const response = await api.getallvehicle({
        type: "vehicle",
        pump: data.pumpid,
        company
      });
      console.log("vehicles", response);
      if (response.message.length > 0 && typeof response.message !== "string"){
        setVehicles(response.message);
        setDatavehicle({
          ...data,
          email: response.message[0]._id
        });
      }
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false)
    }
  };


  const handlepumpChange = (event) => {
    setData({ ...data, pumpid: event.target.value });
  }

  const handlecompanyChange = (event) => {
    setData({ ...data, company: event.target.value });
  }

  const handlemonthChange = (event) => {
    setData({ ...data, month: event.target.value });
  }


  const handlevehiclemonthChange = (event) => {
    setDatavehicle({ ...datavehicle, month: event.target.value });
  }

  const handleemailChange = (event) => {
    setDatavehicle({ ...datavehicle, email: event.target.value });
  }

  const billing = async (e) => {
    try {

      if (user.type == "admin" || (user.type == "pump" && user.canUseAll) || user.type === "vehicle") {

      }
      else {
        swal({
          title: "You are blocked due to some reason contact admin",

          icon: "warning",
        });

      }

      e.preventDefault()
      const payload = {
        email: datavehicle.email,
        month: datavehicle.month
      }

      var priceTotal = 0;
      const response = await api.billingvehicle(payload);
      console.log("vehcileresponse", response.message);
      const bill = `${process.env.REACT_APP_SERVER_BASE_URL}${response.message}`
      window.open(bill);

    } catch (e) {
      console.log(e)
      swal({
        title: "No Transaction Found !",
        icon: "error",
      });
    }
  }

  const billingcompany = async (e) => {
    try {
      e.preventDefault()
      const payload = {
        company: data.company,
        month: data.month
      }
      console.log(payload);
      // const response = await billingapimonthly(payload);

      const response = await api.billingcompany(payload)
      console.log("hellowolrd");
      console.log(response)
      console.log("hellowolrd");
      console.log("companyresponse", response.message);
      const bill = `${process.env.REACT_APP_SERVER_BASE_URL}${response.message}`
      window.open(bill);
    } catch (e) {
      console.log(e)
      swal({
        title: "No Transaction Found !",
        icon: "error",
      });
    }
  }

  return (
    <>

      {/* Begin Page Content */}
      {user.type === "admin" || (user.type === "pump" && user.canUseAll) || (user.type === "company") ? (
        <div className="container-fluid">
          {/* Content Row */}
          <div className="row">
            {/* Area Chart */}
            <div className="col ">
              <div className=" card sortby-box br-10 border-transparent">
                {/* Card Header - Dropdown */}
                <div className="form-group col-lg-8">
                  <label>Pump</label>
                  <select onChange={handlepumpChange} className="form-control theme-bg-color border-0 " name="" id="">
                    <option value="" disabled>Select Pump</option>
                    {typeof pump !== "string" &&
                      pump.map((item) =>
                      (
                        <option value={item._id}>
                          {item.name}({item.email})
                        </option>
                      ))}
                  </select>
                  <label className='mt-3'>Company</label>
                  <select onChange={handlecompanyChange} className="form-control theme-bg-color border-0 " name="" id="">
                    <option value="" disabled>Select Company</option>
                    {typeof company !== "string" &&
                      company.map((item) =>
                      (
                        <option value={item._id}>
                          {item.name}({item.email})
                        </option>
                      ))}
                  </select>
                  <label className='mt-3'>Month</label>
                  <select onChange={handlemonthChange} className="form-control theme-bg-color border-0 " name="" id="">
                    <option value="" disabled>Select Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">Semptember</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div className="card-header br-10  bg-white border-transparent py-3 d-flex flex-row align-items-center ">
                  <a className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 fs-12 " onClick={billingcompany}> Generate Bill</a>
                </div>

              </div>
              {/* table */}

            </div>

            {/* post load / bid column*/}
            {/* <LoadSecondRow /> */}

          </div>
        </div>
      
      ) : (<></>)}

      <div className="mt-5 container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col ">
            <div className=" card sortby-box br-10 border-transparent">
              {/* Card Header - Dropdown */}
              <div className="form-group col-lg-8">
                <label>Vehicle</label>
                <select onChange={handleemailChange} className="form-control theme-bg-color border-0 " name="" id="">
                  <option value="" disabled>Select Vehicle</option>
                  {typeof vehicles !== "string" &&
                    vehicles.map((item) =>
                    (
                      <option value={item._id}>
                        {item.name}({item.email})
                      </option>
                    ))}
                </select>
                <label className='mt-3'>Month</label>
                <select onChange={handlevehiclemonthChange} className="form-control theme-bg-color border-0 " name="" id="">
                  <option value="" disabled>Select Month</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">Semptember</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
              <div className="card-header br-10  bg-white border-transparent py-3 d-flex flex-row align-items-center ">
                <a className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 fs-12 " onClick={billing} > Generate Bill</a>
              </div>

            </div>
            {/* table */}

          </div>

          {/* post load / bid column*/}
          {/* <LoadSecondRow /> */}

        </div>

      </div>
      {/* /.container-fluid */}

    </>
  )
}

export default Pump