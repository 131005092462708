import * as ActionTypes from '../ActionTypes';
import swal from "sweetalert";


export const get_user = async (email, type, token) => {
    return fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/user_by_email`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ email, type })
    });
}

export const loginUserReq = (username, password, type) => (dispatch) => {
    console.log({
        email: username,
        password: password,
        type
    });
    return fetch(process.env.REACT_APP_SERVER_BASE_URL + '/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: username,
            password: password,
            type
        })
    }).then(r => r.json())
        .then(r => {
            console.log(r);
            if (r.success) {
                get_user(username, type, r.message)
                    .then(r => r.json()).then(user => {
                        console.log(user);
                        user.message[0].token = r.message;
                        return dispatch(loginUser(user.message[0]));
                    });
            } else {
                console.log("error here ");
                // return dispatch(loginUserFailed(r.message));
                swal({
                    title: r.message,
                    icon: "error",
                });

            }
        });
}

export const loginUser = (r) => ({
    type: ActionTypes.LOGGED_IN,
    payload: r
})

export const logoutUser = () => ({
    type: ActionTypes.LOGGED_OUT,
})

const loginUserFailed = (errMess) => ({
    type: ActionTypes.LOGIN_USER_FAILED,
})