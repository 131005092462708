import React from 'react';
import Modal from 'react-bootstrap/Modal';

export default function Detail({ show, setShow, detaildata }){
  return(
    <Modal show={show} onHide={() => setShow(false)} centered={true}>
      <Modal.Body>
        <div className="modalForm">
          <h3 className=''>Product Details</h3>
          <div className="form-group">
            <label>Name</label>
            <input value={detaildata?.name} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>

          <div className="form-group">
            <label>Selling Rate</label>
            <input value={detaildata?.sellingrate} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>
          <div className="form-group">
            <label>pump</label>
            <input value={detaildata?.pump?.name} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />

          </div>
          <div className="form-group">
            <label>unit</label>
            <input value={detaildata?.unit} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>

        </div>
      </Modal.Body>
    </Modal>
  );
}