import React,{useEffect,useState} from 'react';
import { BrowserRouter as Router, Switch, Route,useParams } from "react-router-dom";
import uploadIcon  from "../../images/uploadIcon.svg";
import flag from "../../images/flag.svg";
import dropdownIcon from "../../images/three-dots-vertical.svg";
import attachment from "../../images/attachment.svg";
import emoji from "../../images/emoji.svg";
import sendIcon from "../../images/sendIcon.svg";
import recordAudioIcon from "../../images/recordAudioIcon.svg";
import profileSM from "../../images/profileSM.svg";
import typingAnim from "../../images/typing-animation-3x.gif";
import chatUser from "../../images/chatUser.jpg";
import callIcon from "../../images/callIcon.svg";
import callEnd from "../../images/callEnd.svg";
import microphone from "../../images/microphone.svg";
import mute from "../../images/mute.svg";
import "./Chat.css";

import { NavLink } from "react-router-dom";



function Chat() {

 const [muteState,setMuteState]=useState(false)
 const [dialCall,setdialCall]=useState(false)
    return(
      <>
      
      <div className="row mx-0 jc-center">
          <div className="col-11">
              <div className="row mx-0">
                  <div className="col-xl-8 mb-3">
                      <div className="chat-card">
                          <div className="chat-senderDiv">
                              <div className="d-flex ai-center">
                                  <h5 className="mb-0">John Doe</h5>
                                  <div className="onlineSign"></div>
                              </div>
                              <div className="d-flex">
                                  <img src={flag} alt="flag" className="mr-3" />
                                <div>
                                    <div class="dropdown">
                                        <a class=" dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src={dropdownIcon} alt="dropdownIcon" />
                                        </a>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" href="#">Action</a>
                                            <a class="dropdown-item" href="#">Another action</a>
                                            <a class="dropdown-item" href="#">Something else here</a>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            

                          </div>
                          <div className="chatDiv">
                              <div className="userName">
                              <h6>Today</h6>
                              </div>
                              <div className="chatMain">
                                  {/* reveive massage */}
                                <div className="revieveMsg">
                                    <p className="mb-0">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor
                                    </p>
                                    <div className="time">22:08</div>
                                </div>
                                 {/* send massage */}

                                <div className="sendMsg">
                                    <p className="mb-0">
                                        Sure, I am available                                     
                                    </p>
                                    <div className="time">22:08</div>
                                </div>
                                <div className="sendMsg">
                                    <p className="mb-0">
                                        Sure, I am available                                     
                                    </p>
                                    <div className="time">22:08</div>
                                </div>
                                <div className="revieveMsg">
                                    <p className="mb-0">
                                    How about Saturday?                                    
                                    </p>
                                    <div className="time">22:08</div>
                                </div>
                                <div className="sendMsg">
                                    <p className="mb-0">
                                        Sure, I am available                                     
                                    </p>
                                    <div className="time">22:08</div>
                                </div>
                               
                              </div>
                          </div>
                          <div className="receiverTyping">
                            <img src={profileSM} alt="" className="prDiv" />
                            <img src={typingAnim} alt="" className="typingAnimation" />
                          </div>
                          <div className="masgWriteDiv row mx-2">
                              <div className="auto-col p-0">
                                  <img src={recordAudioIcon} alt="record audio" className="px-2" />
                              </div>
                              <div className="col p-0">
                                  <input type="text" placeholder="Type a message..." />
                              </div>
                              <div className="auto-col p-0 d-flex">
                                   <label >
                                    <input type="file" name=""  />
                                    <img src={attachment} alt="attachment" className="mr-2" />
                                   </label>
                                  <img src={emoji} alt="emoji"  className="mr-2" />
                                  <a href className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold fs-12 w-100">
                                      Send
                                      <img src={sendIcon} alt="send" className="ml-2" />
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-xl-4">
                      <div className="chat-card">
                          <div className="chatUserLogo">
                              <img src={chatUser} alt="" />
                          </div>
                          <div className="chatUserInfo">
                                <h6>John Smith</h6>
                                <p>Diver</p>
                                <p>ABC Tracking LLC</p>
                          </div>
                          <div className="chatStatus">
                              <span>Status:</span> <span className="status-loaded">Loaded</span>
                          </div>
                          {dialCall == false?
                          <div className="callDiv">
                            <div className="text-center">
                                <a type="button" onClick={()=>setdialCall(true)}>
                                    <img src={callIcon} alt="make call" />
                                </a>
                            </div>    
                            <div className="">
                                <h6 className="mt-2">Call</h6>
                                <a href="#">Veiw call history</a>
                            </div>
                          </div>
                          :
                            <div className="callDiv">
                            <div className="text-center d-flex ai-center jc-center">
                                {muteState == true?
                                    <a type="button" className="microPhone" onClick={()=>setMuteState(false)}>
                                        <img src={mute} alt="make call" />
                                    </a>
                                :
                                    <a type="button" className="microPhone" onClick={()=>setMuteState(true)}>
                                        <img src={microphone} alt="make call" />
                                    </a>
                                }
                                <a type="button" onClick={()=>setdialCall(false)}>
                                    <img src={callEnd} alt="make call" />
                                </a>
                            </div>    
                            <div className="">
                                <a><span>Connected</span> <span>3:24</span></a>
                            </div>
                          </div>
                          
                          }
                          <div className="ChatAttacment">
                              <h6>Attachment</h6>
                              <div className="attachmentDiv">
                                  <label >
                                      <input type="file" name="" accept="application/pdf" />
                                      <span>PDF</span>
                                  </label>
                                  <label >
                                      <input type="file" accept="video/mp4,video/x-m4v,video/*" />
                                      <span>Video</span>
                                  </label>
                                  <label >
                                      <input type="file" accept=".mp3,audio/*" />
                                      <span>Mp3</span>
                                  </label>
                                  <label >
                                      <input type="file" name="" accept="image/png, image/gif, image/jpeg" />
                                      <span>Image</span>
                                  </label>
                              </div>
                              <div className="text-center mt-2">
                                  <a href="#" className="veiwAllBtn">
                                      Veiw All
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>


              </div>
          </div>
        </div>
      </>
    )
  }

  export default Chat