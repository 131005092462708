import React, { useState } from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import api from "../../apis";
import VehicleDetailModal from "./vehicleDetailModal";
import VehicleEditModal from "./vehicleEditModal";
import rightArrow from "../../images/right-arrow.svg";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import edit from "../../images/edit.svg";
// import EditModal from "./edit";
import moreIcon from '../../images/moreIcon.svg'


export default function VehicleRow({ item, setLoader, fetchvehicle }) {

  const [showDetail, setShowDetail] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const authedUser = useSelector(state => state.authedUser);

  const user = authedUser.authedUser;

  const changeStatus = async (item) => {
    try {
      if (user.type == "admin" || user.type == "company" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true)
        let data = item;
        data.status = !data.status;

        let response = await api.blockvehicle(
          {
            user: {
              _id: item._id,
            },
            update: item,
          }
        )

        // const company = await get_companies({ email: item.company });

        if (!response) {
          throw new Error("Error while updating status");
        }

        // await fetchAllUsers();
        await fetchvehicle();
        // alert("Status Update Successfull.");
        swal({
          title: "STATUS UPDATED SUCCESSFULLY !",
          // text: "You clicked the button!",
          icon: "success",
        });
        setLoader(false)

      }
      else {
        swal({
          title: "You are blocked due to some reason contact admin",

          icon: "warning",
        });

      }
    } catch (e) {
      setLoader(false)
    }
  };


  const deleteUser = async (item) => {
    try {
      if (user.type == "admin" || user.type == "company" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true)
        let data = item;
        // const response = await delete_vehicle(data);
        console.log(data);
        const response = await api.deletevehicle(data)
        if (!response) {
          throw new Error("Error while deleting");
        }
        // await fetchAllUsers();
        await fetchvehicle();
        // alert("Deleted Successfull.");
        swal({
          title: "DELETED SUCCESSFULLY !",
          // text: "You clicked the button!",
          icon: "success",
        });
        setLoader(false)
      }
      else {
        swal({
          title: "You are blocked due to some reason contact admin",

          icon: "warning",
        });

      }

    } catch (e) {
      setLoader(false)
    }
  };

  return (
    <tr>
      <td className="d-flex align-items-center">
        <span className="ml-3">{item?.email}</span>
      </td>
      <td>{item?.name}</td>
      <td>{item?.status ? "Not-Blocked" : "Blocked"}</td>
      <td>{item?.vehicle_number}</td>
      <td>{item?.phone}</td>
      <td>{item?.limit}</td>
      <td>{item?.company?.email}</td>
      <td>
        <img onClick={() => setShowEdit(true)} src={edit} alt="edit" />
      </td>
      <td>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button  {...bindTrigger(popupState)}>
                <img src={moreIcon} alt="" />
              </Button>
              <Menu {...bindMenu(popupState)}>


                <MenuItem
                  onClick={() => { popupState.close(); setShowDetail(true) }}
                >Details</MenuItem>

                <MenuItem
                  onClick={() => changeStatus(item)}
                >
                  {item.status ? "Block" : "Un-Block"}
                </MenuItem>

                <MenuItem onClick={() => {
                  deleteUser(item);
                }}>Delete</MenuItem>

              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </td>

      {showDetail ? <VehicleDetailModal show={showDetail} setShow={setShowDetail} detailData={item} /> : null}
      {showEdit ? <VehicleEditModal show={showEdit} setShow={setShowEdit} fetchCurrent={item} fetchvehicle={fetchvehicle} /> : null}
    </tr>
  );
}