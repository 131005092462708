import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Loader from '../loader';
import swal from 'sweetalert';
import api from '../../apis';
import { useSelector } from 'react-redux';


export default function RequestModal ({ show, setShow, item, fetchData }) {
  const [loader, setLoader] = useState(false);
  const user = useSelector(state => state.authedUser.authedUser);
  
  const [data, setData] = useState({
    desc: item?.requestDescription ? item?.requestDescription : ""
  });

  const validator = () => {
    if (!data.desc) {
      return "Description is required";
    }
    return false;
  };

  const handleDescChange = (event) => {
    setData({ ...data, desc: event.target.value });
  };

  const save = async () => {
    try {
      if (user.type == "admin" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true)
        if (validator()) {
          throw new Error(validator());
        } else {
          if (localStorage.getItem("token")) {
            const payload = {
              _id: item?._id,
              requestDescription: data.desc
            }
            let response = await api.reqresolve(payload);

            if (!response) {
              throw new Error("Error while updating");
            }

            await fetchData();
            setShow(false);
            //alert("Product Update Successfull.");
            swal({
              title: "UPDATE SUCCESSFULLY !",
              // text: "You clicked the button!",
              icon: "success",
            });
          }
        }
        setLoader(false)
      }
      else {
        swal({
          title: "You are blocked due to some reason contact admin",

          icon: "warning",
        });

      }
    } catch (e) {
      console.log(e);
      // alert(e);
      swal({
        title: e,

        icon: "warning",
      });
      setLoader(false)
    }
  }

  return(
    <Modal show={show} onHide={() => setShow(false)} centered={true}>
      <Modal.Body>
        <div className="modalForm">
          <h3 className=''>Resolve Request Description</h3>
          <div className="form-group">
            <label htmlFor="edit-temp-limit">Description</label>
            <input type="text" value={data?.desc} onChange={handleDescChange} className="form-control theme-bg-color border-0 small" id="edit-temp-limit" aria-describedby="emailHelp" placeholder="Enter Description" />
          </div>

          <div className='text-center subBtn'>
            <button onClick={save} class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10">Submit</button>
          </div>
        </div>
      </Modal.Body>
      {loader && <Loader />}
    </Modal>
  );
}