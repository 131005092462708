import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import HRimage from "../imageupload/hr"
import api from '../../apis';
import Add from './add';
import Row from './row';
import searchIcon from "../../images/search.svg";

function Pump() {
  const [show, setShow] = useState(false);

  const [selectedValue, setSelectedValue] = React.useState('a');

  const [hrs, setHrs] = useState([]);
  useEffect(() => {
    fetchallhrs();
  }, []);

  const fetchallhrs = async (filter = "") => {
    console.log({
      name: {"$regex": filter, "$options": "i"}
    });
    const response = await api.getallhr({
      name: {"$regex": filter, "$options": "i"}
    });
    console.log("hr", response.data);
    setHrs(response.data)
  }

  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const filteredHr = async () => {
    let filter = document.getElementById('hr-filter-name').value ? document.getElementById('hr-filter-name').value : "";
    fetchallhrs(filter);
  }

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col ">
            <div className=" card sortby-box br-10 border-transparent">
              {/* Card Header - Dropdown */}
              <div className="card-header br-10  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">

                <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                  <h6>Filters</h6>
                  <div className="input-group">

                    <input type="text" id="hr-filter-name" className="form-control theme-bg-color border-0 small" placeholder="Enter Name" aria-label="Search" aria-describedby="basic-addon2" />
                    <div className="input-group-append">

                      <button className="btn search-btn pr-4" onClick={filteredHr} type="button">
                        <img src={searchIcon} className="w-15 mb-1" alt="serach icon" />
                      </button>
                    </div>
                  </div>
                </form>
                <a className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 fs-12" onClick={() => handleShow()}><span className="fs-14">+</span> Add New HR</a>
              </div>

            </div>
            {/* table */}
            <Table fetchData={fetchallhrs} hrs={hrs} />
          </div>

          {/* post load / bid column*/}
          {/* <LoadSecondRow /> */}

        </div>

      </div>
      <Add fetchData={fetchallhrs} show={show} setShow={setShow} />
      {/* /.container-fluid */}

    </>
  )
}


const Table = ({ fetchData, hrs }) => {
  const user = useSelector(state => state.authedUser.authedUser);
  const [editshow, seteditShow] = useState(false);
  const [detailshow, setdetailShow] = useState(false);
  const [data, setData] = React.useState({
    cnic: "",
    name: "",
    description: "",
    phone: "",
    stolen: "",
    pump: "",
  });
  const [imgdata, setImgdata] = React.useState({});
  const edithandleClose = () => seteditShow(false);
  const edithandleShow = () => seteditShow(true);

  const detailhandleClose = () => setdetailShow(false);
  const detailhandleShow = () => setdetailShow(true);

  const handlePumpChange = (event) => {
    setData({ ...data, pump: event.target.value });
  };

  const handlePhoneChange = (event) => {
    setData({ ...data, phone: event.target.value });
  };

  const handleCNICChange = (event) => {
    setData({ ...data, cnic: event.target.value });
  };
  const handleNameChange = (event) => {
    setData({ ...data, name: event.target.value });
  };
  const handleDescChange = (event) => {
    setData({ ...data, description: event.target.value });
  };
  const handleStolenChange = (event) => {
    setData({ ...data, stolen: event.target.value });
  };

  const addfunc = () => {

  }


  return (
    <div className="card sortby-box br-10 border-transparent mt-3">
      <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
        <table class="table table-hover">
          <thead>
            <tr>
              <th className=" pl-4">CNIC</th>
              <th className="">Name</th>
              <th className="">Description</th>
              <th className="">Pump Name</th>

              {user?.type === "admin" && (
                <th className="">Request Resolved</th>
              )}
              <th className="">Request Description</th>
              <th className="">Resolved</th>
              <th className="">Resolved Description</th>
              {user.type === "admin" && (
                <th className="">Approved</th>
              )}
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {hrs.map(item => {
              return (
                <Row item={item} key={item._id} fetchData={fetchData} />
              );
            })}
          </tbody>
        </table>
      </div>

      {/* <EditModal open={editModal} setOpen={closeModal} /> */}
      <Modal show={editshow} onHide={edithandleClose} centered={true}>
        <Modal.Body>
          <div className="modalForm">
            <h3 className=''>Edit HR</h3>
            <div className="form-group">
              <label>CNIC</label>
              <input type="number" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter CNIC" onChange={handleCNICChange} />
            </div>
            <div className="form-group">
              <label>Name</label>
              <input type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name" onChange={handleNameChange} />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" onChange={handleDescChange} />
            </div>
            <div className="form-group">
              <label>Phone</label>
              <input type="number" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Phone" onChange={handlePhoneChange} />
            </div>
            <div className="form-group">
              <label>Stolen Amount</label>
              <input type="number" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Stolen Amount" onChange={handleStolenChange} />
            </div>


            <div className="form-group">
              <label>pump</label>
              <select className="form-control theme-bg-color border-0 " name="" id="" onChange={handlePumpChange}>
                <option value="" disabled>Select pump</option>
                <option value="1">two</option>
              </select>
            </div>

            <div className="form-group">
              <label>Upload Image</label>
              <HRimage setImgdata={setImgdata} />
            </div>

            <div className='text-center subBtn'>
              <button class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10" onClick={addfunc} >Submit</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
const LoadSecondRow = () => {
  return (
    <div className="col-xl-4 col-lg-4 mb-3">
      <div className="card h-100 mb-4 br-10 border-transparent overflow-hidden">
        {/* Card Header - Dropdown */}
        <div className="card-header border-bootom-light bg-white  py-3 d-flex flex-row align-items-center justify-content-between">
          <div>
            <div className="color-dark fs-19 font-weight-bold">$1,850</div>
            <div className="color-light font-weight-bold fs-12">$2.78/mi</div>
          </div>
          <div>
            <div className="color-dark fs-19 font-weight-bold">664mi</div>
            <div className="color-light font-weight-bold fs-12">Mon 4/26</div>
          </div>
        </div>

        {/* Card header */}
        <div className="card-header border-bootom-light bg-white  py-3 ">
          {/* driver card row */}
          <div className="row py-2  px-0">
            <div className="col-auto">
              <div className="circle-light"></div>
              <div class="vertical-line-light m-auto"></div>
              <div className="circle"></div>
              <div class="vertical-line-dark m-auto"></div>
              <div className="circle-fill"></div>

            </div>
            <div className="col ">
              <p className="color-light fs-13 font-weight-600 mb-0">Deadhead</p>
              <div className="d-flex">
                <hr className="w-75 ml-0 border-top-light" />
                <p className="deadhead-mi m-auto">43 mi</p>
              </div>
              <div className="deadhead-title">Miami, FL</div>
              <div className="deadhead-time">Mon 4/26, 9:30 EST</div>
              <div className="d-flex">
                <hr className="w-75 ml-0 border-top-light" />
                <p className="deadhead-mi m-auto">664 mi</p>
              </div>
              <div className="deadhead-title">Atlanta, GA</div>
              <div className="deadhead-time">Tue 4/27, 17:30 - 19:30 EST</div>

            </div>
          </div>
        </div>

        <div className="card-header bg-white border-transparent pb-2 pt-3 d-flex flex-row align-items-center justify-content-between">
          <div>
            <div className="color-light font-weight-600 fs-10">Trailer Type</div>
            <div className="color-dark fs-17 font-weight-bold">Reefer</div>
          </div>
          <div>
            <div className="color-light font-weight-600 fs-10">Trailer Length</div>
            <div className="color-dark fs-17 font-weight-bold">53'</div>
          </div>
        </div>
        <div className="card-header bg-white border-transparent py-2 d-flex flex-row align-items-center justify-content-between">
          <div>
            <div className="color-light font-weight-600 fs-10">Full or partial</div>
            <div className="color-dark fs-17 font-weight-bold">Full</div>
          </div>
          <div>
            <div className="color-light font-weight-600 fs-10">WEIGHT</div>
            <div className="color-dark fs-17 font-weight-bold">35,000 lbs</div>
          </div>
        </div>
        <div className="card-header border-bootom-light bg-white border-transparent pt-2 pb-3">
          <div>
            <div className="color-light font-weight-600 fs-10">Requirement</div>
            <div className="color-dark fs-17 font-weight-bold">CARB, Temp control to 28 F</div>
          </div>
        </div>
        <div className="card-header bg-white border-transparent pt-3 pb-2">
          <div>
            <div className="color-light font-weight-600 fs-10">Commodity</div>
            <div className="color-dark fs-17 font-weight-bold">Refrigerated Produce</div>
          </div>
        </div>
        <div className="card-header border-bootom-light bg-white pt-2 pb-3">
          <div>
            <div className="color-light font-weight-600 fs-10 mb-1">Comment</div>
            <div className="color-light font-weight-600 fs-11">Driver will be required to wear safety vest while at the shippers facility</div>
          </div>
        </div>
        {/* bid and post button */}
        <div className="card-header bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
          <a href="" class=" btn border-transparent loadBtnColor-underline font-weight-bold fs-12 w-100">Bid</a>
          <a href="" class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold fs-12 w-100 ml-2"><span class="fs-14">+</span> Add New Load</a>
        </div>


      </div>
    </div>
  )
}
export default Pump