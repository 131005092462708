import React from "react";
import Modal from 'react-bootstrap/Modal';

export default function DetailPumpModal ({ detailshow, detailhandleClose, detaildata }) {

  return(
    <Modal show={detailshow} onHide={detailhandleClose} centered={true}>
      <Modal.Body>
        <div className="modalForm">
          <h3 className=''>Pump Details</h3>
          <div className="form-group">
            <label>Name</label>
            <input value={detaildata?.name} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>
          <div className="form-group">
            <label>Email address</label>
            <input value={detaildata?.email} type="email" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
          </div>
          <div className="form-group">
            <label>Address</label>
            <input value={detaildata?.address} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>

          <div className="form-group">
            <label>Oil Marketing Company</label>
            <input value={detaildata?.omc} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>

          <div className="form-group">
            <label>PTCL</label>
            <input value={detaildata?.manager_phone} type="number" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>
          <div className="form-group">
            <label>Contact Number</label>
            <input value={detaildata?.owner_phone} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>
          <div className="form-group">
            <label>City</label>
            <input value={detaildata?.city} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>
          <div className="form-group">
            <label>State</label>
            <input value={detaildata?.state} type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}