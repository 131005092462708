import axios from "../helpers/axios.js";

const api = {};

api.login = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/login`;
    return axios.post(url, body);
};

api.register = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/register`;
    return axios.post(url, body);
};

//company
api.getcompanies = function (body) {
    // const token = localStorage.getItem("token")

    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/users_by_company`;
    return axios.post(url, body, config);
};

api.blockcompany = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/update_company`;
    return axios.post(url, body);
}
api.deletecompany = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/delete_company`;
    return axios.post(url, body);
};
api.registercompany = function (body) {
    // const token = localStorage.getItem("token")

    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/register_company`;
    return axios.post(url, body, config);
};

api.deletecompany = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/delete_company`;
    return axios.post(url, body);
};

api.getpumpsforcompany = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/users_by_pump`;
    return axios.post(url, body);
};
api.update_temp = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/update_temp`;
    return axios.post(url, body, config);
}
api.update_company = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/update_company`;
    return axios.post(url, body, config);
}


//vehicles
api.getallvehicle = function (body) {

    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/users_by_vehicle`;
    return axios.post(url, body, config);

};
api.deletevehicle = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/delete_vehicles`;
    return axios.post(url, body);
};

api.blockvehicle = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/update_vehicle`;
    return axios.post(url, body);
};

api.registervehicle = function (body) {
    // const token = localStorage.getItem("token")

    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/register_vehicle`;
    return axios.post(url, body, config);
};

api.update_vehicle = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    };
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/update_vehicle`;
    return axios.post(url, body, config);
}
//products
api.readproducts = function (body) {

    // const token = localStorage.getItem("token")

    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/read_product`;
    return axios.post(url, body, config);
};

api.getproducts = function (body) {
    // const token = localStorage.getItem("token")

    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/getproduct`;
    return axios.post(url, body);

};

api.deleteproducts = function (body) {
    // const token = localStorage.getItem("token")
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/delete_product`;
    return axios.post(url, body, config);
};

api.createproducts = function (body) {
    // const token = localStorage.getItem("token")
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/create_product`;
    return axios.post(url, body, config);
};
api.update_products = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/update_product`;
    return axios.post(url, body, config);
}

//inventory

api.readinventory = function (body) {
    // const token = localStorage.getItem("token")
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/read_inventory`;
    return axios.post(url, body, config);
};

api.deleteinventory = function (body) {
    // const token = localStorage.getItem("token")
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/delete_inventory`;
    return axios.post(url, body, config);
};


api.getinventory = function (body) {
    // const token = localStorage.getItem("token")
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/getinventory`;
    return axios.post(url, body, config);
};


api.createinventory = function (body) {
    // const token = localStorage.getItem("token")
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/create_inventory`;
    return axios.post(url, body, config);
};

api.update_inventory = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/update_inventory`;
    return axios.post(url, body, config);
}

//pumps

api.deletepump = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/delete_pump`;
    return axios.post(url, body);
};

api.updatepump = function (body) {
    // const token = localStorage.getItem("token")
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/update_pump`;
    return axios.post(url, body, config);
};

api.pumpapprove = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/pumpapprove`;
    return axios.post(url, body);
};


api.registerpump = function (body) {
    // const token = localStorage.getItem("token")
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/register_pump`;
    return axios.post(url, body);
};


//transactions


api.readtransaction = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/read_transaction`;
    return axios.post(url, body);
};


//billing

api.billingcompany = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/monthlybill-company`;
    return axios.post(url, body);
};


api.billingvehicle = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/monthlybill`;
    return axios.post(url, body);
};


//imageupload


api.imgupload = function (body) {
    // const token = localStorage.getItem("token")
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/file/upload`;
    return axios.post(url, body, config);

};

//staff
api.get_staff = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/users_by_staff`;
    return axios.post(url, body, config);
};

api.register_staff = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/register_staff`;
    return axios.post(url, body, config);
}

api.update_staff = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/update_staff`;
    return axios.post(url, body, config);
}

api.delete_staff = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/delete_staff`;
    return axios.post(url, body, config);
}


//pump payments

api.getallpumpreceipt = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/getallpumpreceipt`;
    return axios.get(url, body);
};


api.getallpumpreceiptbypump = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/getallpumpreceiptbypump`;
    return axios.post(url, body);
};


api.editpumpreceipt = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/editpumpreceipt`;
    return axios.post(url, body);
};

api.rejectpumpreceipt = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/rejectpumpreceipt`;
    return axios.post(url, body);
};

api.findpumpreceipt = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/findreceipt`;
    return axios.post(url, body);
};

api.createpumpreceipt = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/pumpreceipt`;
    return axios.post(url, body);
};


//attendance

api.getallstaffattendance = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get-staff-attendance`;
    return axios.post(url, body);
};

api.getallattendance = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get-all-attendance`;
    return axios.post(url, body, config);
};

api.getstaff = function (body) {
    // const token = localStorage.getItem("token")
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/users_by_staff`;
    return axios.post(url, body, config);
};

api.readtransaction = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/read_transaction`;
    return axios.post(url, body);
};

api.createattendance = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/create-attendance`;
    return axios.post(url, body);
};


api.getattendance = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get-attendance`;
    return axios.post(url, body);
};


api.updateattendance = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/update-attendance`;
    return axios.post(url, body);
};

//hr
api.getallhr = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/hr/get-all`;
    return axios.post(url, body, config);
};
api.createHR = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/hr/create`;
    return axios.post(url, body, config);
}
api.hr_approve = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/hr/approve`;
    return axios.post(url, body, config);
}
api.reqresolve = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/hr/reqresolve`;
    return axios.post(url, body, config);
}
api.Cancelreq = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/hr/cancelreq`;
    return axios.post(url, body, config);
}
api.ResolveAPIHR = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/hr/resolve`;
    return axios.post(url, body, config);
}
api.UnResolveAPIHR = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/hr/unresolve`;
    return axios.post(url, body, config);
}

api.getHR = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/hr/get-is-user-approved`;
    return axios.post(url, body);
}

api.getHRPhone = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/hr/get-is-user-approvedbyphone`;
    return axios.post(url, body);
}

//reset pass
api.resetpass = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/reset-by-email`;
    return axios.post(url, body);
};

api.verifyotp = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/verify-otp-change-pass`;
    return axios.post(url, body);
};

//registermanually 
api.createAccount = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/register_account`;
    return axios.post(url, body);
};

// receipt or payments
api.get_payments = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get-all`;
    return axios.post(url, body, config);
}
api.create_receipt = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/create`;
    return axios.post(url, body, config);
}

api.setpwd = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/set-password`;
    return axios.post(url, body);
}
api.setpwdalready = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/set-password-already`;
    return axios.post(url, body);
}


api.usercount = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/usercount`;
    return axios.post(url, body);
}

api.productcount = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/countproduct`;
    return axios.post(url, body, config);
}

//acounting

api.managenozels = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/manage-nozels`;
    return axios.post(url, body, config);
}

api.sheetmanagement = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/create-daily-sheet-record`;
    return axios.post(url, body, config);
}


api.managebankdetails = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/manage-bank-details`;

    return axios.patch(url, body, config);
}

api.virtualpay = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/manage-virtual-payments`;
    return axios.patch(url, body, config);
}



api.Editvirtualpay = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/Edit-virtual-payments`;
    return axios.patch(url, body, config);
}

api.syncproducts = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get-product-with-inventory-count`;
    return axios.get(url, body, config);
}



api.getnozels = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get-nozels`;
    return axios.get(url, body, config);
}


api.createPaymentTrx = function (body) {
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/create-payment-expense-transaction`;
    return axios.post(url, body, config);
}

export default api;