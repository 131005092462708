import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import rightArrow from "../../images/right-arrow.svg";
import PDF from "../PDF/pdf.js";

import api from "../../apis/index";
import { v4 as uuidv4 } from "uuid";
import Loader from "../loader";
import swal from "sweetalert";
import { useSelector } from "react-redux";

function Pump() {
  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid ">
        {/* Content Row */}
        <div className="row ">
          {/* Area Chart */}
          <div className="col ">
            {/* table */}
            <Table />
          </div>
        </div>
      </div>
    </>
  );
}

const Table = () => {
  const authedUser = useSelector((state) => state.authedUser);
  const user = authedUser.authedUser;
  const [filter, setFilterData] = useState({
    pump: "",
    company: "",
    vehicle: "",
    staff: "",
    product: "",
    startDate: "",
    endDate: "",
  });
  const [data, setData] = useState([]);
  const [pumps, setPumps] = useState([]);
  const [company, setCompany] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [staff, setStaff] = useState([]);
  const [product, setProducts] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pdfData, setPdfData] = useState([]);
  const [dueAmount, setDueAmount] = useState(0);
  const [paymentReceived, setPaymentReceived] = useState(0);
  const [dueAmountAfterPayment, setDueAmountAfterPayment] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
    setFilterData({
      ...filter,
      startDate: e.target.value,
    });
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
    setFilterData({
      ...filter,
      endDate: e.target.value,
    });
  };

  // useEffect(() => {
  //   if (user.type === "pump") {

  //     setFilterData({
  //       ...filter,
  //       pump: user._id,
  //     });
  //   }
  //   if (user.type === "vehicle") {
  //     setFilterData({
  //       ...filter,
  //       pump: user.pump,
  //       company: user.company,
  //       vehicle: user._id,
  //     });
  //   }
  //   fetchAllTransaction();
  //   fetchAllPumps();
  //   // fetchAllStaff();
  //   // fetchAllVehicles();
  //   // fetchAllCompany();
  //   // fetchAllProduct();

  // }, []);

  // useEffect(() => {
  //   fetchAllTransaction();
  //   fetchAllStaff();
  //   // fetchAllVehicles();
  //   fetchAllCompany();
  //   fetchAllProduct();
  // }, [filter.pump]);

  // useEffect(() => {
  //   fetchAllTransaction();
  //   fetchAllVehicles();
  // }, [filter.company]);

  useEffect(() => {
    fetchAllTransaction();
  }, [filter.vehicle]);

  useEffect(() => {
    fetchAllTransaction();
  }, [filter.staff]);

  useEffect(() => {
    fetchAllTransaction();
  }, [filter.product]);

  useEffect(() => {
    fetchAllTransaction();
    if (user.type === "admin") {
      fetchAllPumps();
    }
    if (user.type === "pump") {
      setFilterData({
        ...filter,
        pump: user._id,
      });
    }
    if (user.type === "vehicle") {
      setFilterData({
        ...filter,
        pump: user.pump._id,
        company: user.company._id,
        vehicle: user._id,
      });
    }
    if (user.type === "company") {
      setFilterData({
        ...filter,
        company: user._id,
        pump: user.pump._id,
      });
    }
  }, []);

  useEffect(() => {
    fetchAllTransaction();
    fetchAllCompany();
    fetchAllProduct();
    fetchAllStaff();
  }, [filter.pump]);

  useEffect(() => {
    fetchAllTransaction();
    fetchAllVehicles();
  }, [filter.company]);

  useEffect(() => {
    toPDFData();
  }, [data]);

  const toPDFData = () => {
    try {
      let totalDueBeforePayment = 0;
      let totalPayment = 0;
      let totalDueAfterPayment = 0;
      let tarray1 = [];
      let tarray2 = [];
      let grandTotal = 0;
      setLoader(true);
      for (let i = 0; i < data.length; i++) {
        const { total, payment } = data[i];

        // If payment is true, add payment row
        if (payment) {
          let date = data[i]?.date.split("T");
          tarray1.push(
            "Payment To Pump",
            "-",
            "-",
            total.toFixed(2),
            date[0],
            "-",
            "Credited"
          );
          tarray2.push(tarray1);
          tarray1 = [];

          totalPayment += total;
        } else {
          let date = data[i]?.date.split("T");

          totalDueBeforePayment += total;
          grandTotal += total;

          tarray1.push(
            data[i]?.productId?.name ? data[i]?.productId?.name : "-",
            data[i]?.rate.toFixed(2) ? data[i]?.rate.toFixed(2) : "-",
            data[i]?.quantity.toFixed(2) ? data[i]?.quantity.toFixed(2) : "-",
            total.toFixed(2),
            date[0],
            data[i]?.vehicleEmail ? data[i]?.vehicleEmail : "-",
            "Debited"
          );

          tarray2.push(tarray1);
          tarray1 = [];
        }
      }

      // Calculate total due after payment
      totalDueAfterPayment = totalDueBeforePayment - totalPayment;

      tarray2.push(["", "", "", "", "", ""]);
      tarray2.push(["", "", "", "", "", ""]);
      tarray2.push([
        "Total Due Before Payment",
        "",
        "",
        totalDueBeforePayment.toFixed(2),
        "",
        "",
      ]);
      tarray2.push([
        "Total Payment Received",
        "",
        "",
        totalPayment.toFixed(2),
        "",
        "",
      ]);
      tarray2.push([
        "Due After Payment (Grand Total)",
        "",
        "",
        totalDueAfterPayment.toFixed(2),
        "",
        "",
      ]);

      setPdfData(tarray2);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const fetchAllPumps = async () => {
    try {
      // setLoader(true);
      const response = await api.getpumpsforcompany({
        type: "pump",
      });
      console.log("pumps", response);
      setPumps(response.message);
      // setLoader(false);
    } catch (e) {
      console.log(e);
      // setLoader(false);
    }
  };

  const fetchAllCompany = async () => {
    try {
      // setLoader(true);

      console.log("filter pump", filter.pump);
      // let params = {
      // }
      // if (user.type === 'pump'){
      //   params = {
      //     ...params,
      //     pump: user._id
      //   };
      // }
      const response = await api.getcompanies({
        type: "company",
        pump: filter.pump,
      });

      console.log("company", response);

      setCompany(response.message);
      // setLoader(false);
    } catch (e) {
      console.log(e);
      // setLoader(false);
    }
  };

  const fetchAllVehicles = async () => {
    try {
      // setLoader(true);
      let params = {
        type: "vehicle",
      };
      // if (user.type === 'company'){
      //   params = {
      //     ...params,
      //     company: user._id,
      //     pump: user.pump
      //   };
      // }
      // else if (user.type === 'pump'){
      //   params = {
      //     ...params,
      //     pump: user._id
      //   };
      // }
      const response = await api.getallvehicle({
        type: "vehicle",
        company: filter.company,
      });
      console.log("vehicle", response);
      setVehicle(response.message);
      // setLoader(false);
    } catch (e) {
      console.log(e);
      // setLoader(false);
    }
  };

  const fetchAllStaff = async () => {
    try {
      // setLoader(true);
      // let params = {
      //   type: "staff"
      // };
      // if (user.type === 'pump'){
      //   params = {
      //     ...params,
      //     pump: user._id
      //   };
      // }
      const response = await api.get_staff({
        type: "staff",
        pump: filter.pump,
      });
      console.log("staff", response);
      setStaff(response.message);
      // setLoader(false);
    } catch (e) {
      console.log(e);
      // setLoader(false);
    }
  };

  const fetchAllProduct = async () => {
    try {
      // setLoader(true);
      // const response = await get_products();
      const response = await api.getproducts({ pump: filter.pump });
      console.log("product", response);
      setProducts(response.message);
      // setLoader(false);
    } catch (e) {
      console.log(e);
      // setLoader(false);
    }
  };

  const fetchAllTransaction = async () => {
    try {
      setData([]);
      // setLoader(true);
      let date;
      let dateStr = null;
      // let param = {
      //   pump: filter.pump,
      //   company: filter.company,
      //   vehicle: filter.vehicle,
      //   cashier: filter.staff,
      //   productId: filter.product,
      //   startDate: filter.startDate,
      // };
      let param = {};
      if (filter.pump) {
        param["pump"] = filter.pump;
      } else if (user.type === "pump") {
        param["pump"] = user._id;
      } else if (user.type === "company" || user.type === "staff") {
        param["pump"] = user.pump;
      }
      if (filter.company) {
        param["company"] = filter.company;
      } else if (user.type === "company") {
        param["company"] = user._id;
      } else if (user.type === "vehicle") {
        param["company"] = user.company;
      }
      if (filter.vehicle) {
        param["vehicle"] = filter.vehicle;
      } else if (user.type === "vehicle") {
        param["vehicle"] = user._id;
      }
      if (filter.staff) {
        param["cashier"] = filter.staff;
      } else if (user.type === "staff") {
        param["cashier"] = user._id;
      }
      if (filter.product) {
        param["productId"] = filter.product;
      }

      if (filter.startDate) {
        date = new Date(filter.startDate);
        dateStr = date.toISOString();
        param["startDate"] = dateStr;
      }

      if (filter.endDate) {
        date = new Date(filter.endDate);
        dateStr = date.toISOString();
        param["endDate"] = dateStr;
      }

      if (filter.pump) {
        param["pump"] = filter.pump;
      }

      if (filter.company) {
        param["company"] = filter.company;
      }

      console.log("filter", filter);

      if (filter.vehicle) {
        param["vehicle"] = filter.vehicle;
      }

      if (filter.staff) {
        param["cashier"] = filter.staff;
      }

      console.log("filter", filter);
      console.warn(param);
      console.log("helo");
      const response = await api.readtransaction(param);
      console.log("transresp", response);
      setData(response.message);
      console.log(response);
      // setLoader(false);
    } catch (e) {
      console.log(e);
      // setLoader(false);
    }
  };

  const fetchAllTransactionNew = async () => {
    try {
      // setLoader(true);
      let param = {};
      if (user.type == "pump") {
        param = {
          pump: user._id,
        };
      }
      if (user.type == "company") {
        param = {
          company: user._id,
        };
      }
      if (user.type == "vehicle") {
        param = {
          vehicle: user._id,
        };
      }
      if (user.type == "admin") {
        param = {};
      }
      console.warn(param);
      console.log("helo");
      const response = await api.readtransaction(param);
      console.log("transresp", response);
      setData(response.message);
      console.log(response);
      // setLoader(false);
    } catch (e) {
      console.log(e);
      // setLoader(false);
    }
  };

  const onChangeFilterDataRecall = () => {
    fetchAllTransaction();
  };

  useEffect(() => {
    onChangeFilterDataRecall();
  }, [filter]);

  const handlepumpchange = (event) => {
    setFilterData({
      ...filter,
      pump: event.target.value,
      company: "",
      vehicle: "",
      staff: "",
      product: "",
    });
  };

  const handlecompanychange = (event) => {
    setFilterData({ ...filter, company: event.target.value, vehicle: "" });
  };

  const handlevehiclechange = (event) => {
    setFilterData({ ...filter, vehicle: event.target.value });
  };

  const handlestaffchange = (event) => {
    setFilterData({ ...filter, staff: event.target.value });
  };

  const handleproductchange = (event) => {
    setFilterData({ ...filter, product: event.target.value });
  };

  const itemsperpages = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / itemsperpages);
  const itemsInCurrentPage = data.slice(
    (currentPage - 1) * itemsperpages,
    currentPage * itemsperpages
  );

  useEffect(() => {
    if (!Array.isArray(itemsInCurrentPage)) {
      // itemsInCurrentPage is not an array, set all calculated values to 0
      setDueAmount(0);
      setPaymentReceived(0);
      setDueAmountAfterPayment(0);
      return;
    }

    const filteredItems = itemsInCurrentPage.filter(
      (item) =>
        user.type === "company" ||
        (user.type === "pump" &&
          filter.company &&
          item.company === filter.company)
    );

    const dueAmount = filteredItems.reduce((total, item) => {
      if (!item.payment) {
        return total + item.total;
      }
      return total;
    }, 0);
    setDueAmount(dueAmount);

    const paymentReceived = filteredItems.reduce((total, item) => {
      if (item.payment) {
        return total + item.total;
      }
      return total;
    }, 0);
    setPaymentReceived(paymentReceived);

    const dueAmountAfterPayment = dueAmount - paymentReceived;
    setDueAmountAfterPayment(dueAmountAfterPayment);
  }, [user, filter, company, itemsInCurrentPage]);

  return (
    <>
      <div className=" card sortby-box br-10 border-transparent">
        {/* Card Header - Dropdown */}
        <div className="card-header br-10  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
          <div className="w-100 d-flex ">
            {user.type === "admin" ? (
              <div className=" form-group">
                <label>Pump</label>
                <select
                  className="form-control theme-bg-color border-0 "
                  name=""
                  id=""
                  onChange={handlepumpchange}
                >
                  <option value="" disabled selected>
                    Select Pump
                  </option>
                  {typeof pumps !== "string" &&
                    pumps.map((item) => (
                      <option value={item._id}>
                        {item.name}({item.email})
                      </option>
                    ))}
                </select>
              </div>
            ) : (
              <></>
            )}

            {user.type === "admin" || user.type === "pump" ? (
              <div className="ml-5 form-group">
                <label>Company</label>
                <select
                  onChange={handlecompanychange}
                  className="form-control theme-bg-color border-0 "
                  name=""
                  id=""
                >
                  <option value="" selected>
                    Select Company
                  </option>
                  {typeof company !== "string" &&
                    company.map((item) => (
                      <option value={item._id}>
                        {item.name}({item.email})
                      </option>
                    ))}
                </select>
              </div>
            ) : (
              <></>
            )}

            {user.type === "admin" ||
            user.type === "pump" ||
            user.type === "company" ? (
              <div className="ml-5  form-group">
                <label>Vehicle</label>
                <select
                  onChange={handlevehiclechange}
                  className="form-control theme-bg-color border-0 "
                  name=""
                  id=""
                >
                  <option value="" selected>
                    Select Vehicle
                  </option>
                  {typeof vehicle !== "string" &&
                    vehicle.map((item) => (
                      <option value={item._id}>
                        {item.name}({item.email})
                      </option>
                    ))}
                </select>
              </div>
            ) : (
              <></>
            )}

            {user.type === "admin" || user.type === "pump" ? (
              <div className="ml-5  form-group">
                <label>Staff</label>
                <select
                  onChange={handlestaffchange}
                  className="form-control theme-bg-color border-0 "
                  name=""
                  id=""
                >
                  <option value="" selected>
                    Select Staff
                  </option>
                  {typeof staff !== "string" &&
                    staff.map((item) => (
                      <option value={item._id}>
                        {item.name}({item.email})
                      </option>
                    ))}
                </select>
              </div>
            ) : (
              <></>
            )}

            <div className="ml-5 form-group">
              <label>Product</label>
              <select
                onChange={handleproductchange}
                className="form-control theme-bg-color border-0 "
                name=""
                id=""
              >
                <option value="" selected>
                  Select Product
                </option>
                {typeof product !== "string" &&
                  product.map((item) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
              </select>
            </div>

            <div className="ml-5 form-group">
              <label>Start Date</label>

              <input
                type="date"
                className="form-control theme-bg-color border-0 "
                name=""
                id=""
                onChange={(e) =>
                  setFilterData({ ...filter, startDate: e.target.value })
                }
              />
            </div>

            <div className="ml-5 form-group">
              <label>End Date</label>
              {/*  disable when start date not available */}
              <input
                type="date"
                className="form-control theme-bg-color border-0 "
                name=""
                id=""
                onChange={(e) =>
                  setFilterData({ ...filter, endDate: e.target.value })
                }
              />
            </div>
          </div>
        </div>
      </div>
      {user.type === "company" || (user.type === "pump" && filter?.company) ? (
        <div className="card sortby-box br-10 border-transparent mt-3 p-5 pay-block">
          <span>
            Selected Company:{" "}
            <span>
              {
                user.type === "company"
                  ? user.name
                  : filter?.company &&
                    company.find((c) => c._id === filter.company)?.name // map the company name from the company array
              }
            </span>
          </span>
          <span>
            Due Amount:{" "}
            <span>
              {/* Calculate due amount for selected company */}
              {typeof itemsInCurrentPage === "string"
                ? 0
                : Array.isArray(itemsInCurrentPage)
                ? itemsInCurrentPage
                    .filter(
                      (item) => {
                        console.log(item)
                          return (
                        user.type === "company" ||
                        (user.type === "pump" &&
                          filter?.company &&
                          item.company._id == filter.company)
                    )})
                    .reduce((total, item) => {
                      console.log(total)
                      console.log(item)
                      if (!item.payment) {
                        return total + item.total;
                      }
                      return total;
                    }, 0)
                : 0}
            </span>
          </span>
          <span>
            Payment Received: <span>
                 {/* Calculate due amount for selected company */}
                 {typeof itemsInCurrentPage === "string"
                ? 0
                : Array.isArray(itemsInCurrentPage)
                ? itemsInCurrentPage
                    .filter(
                      (item) => {
                        console.log(item)
                          return (
                        user.type === "company" ||
                        (user.type === "pump" &&
                          filter?.company &&
                          item.company._id == filter.company)
                    )})
                    .reduce((total, item) => {
                      console.log(total)
                      console.log(item)
                      if (item.payment) {
                        return total + item.total;
                      }
                      return total;
                    }, 0)
                : 0}
            </span>
          </span>
          <span>
            Due Amount After Payment: <span>{typeof itemsInCurrentPage === "string"
  ? 0
  : Array.isArray(itemsInCurrentPage)
  ? itemsInCurrentPage
      .filter(
        (item) =>
          user.type === "company" ||
          (user.type === "pump" &&
            filter?.company &&
            item.company._id == filter.company)
      )
      .reduce(
        (total, item) => {
          if (!item.payment) {
            return total + item.total;
          }
          return total;
        },
        0
      ) -
      itemsInCurrentPage
        .filter(
          (item) =>
            user.type === "company" ||
            (user.type === "pump" &&
              filter?.company &&
              item.company._id == filter.company)
        )
        .reduce(
          (total, item) => {
            if (item.payment) {
              return total + item.total;
            }
            return total;
          },
          0
        )
  : 0}</span>
          </span>
        </div>
      ) : (
        <></>
      )}

      <div className="card sortby-box br-10 border-transparent mt-3">
        <div className="mt-5 card-header br-10 table-responsive  bg-white border-transparent py-3 d-flex flex-column align-items-center justify-content-between">
          <div className="w-100 d-flex ">
            <PDF
              rows={[
                "Product",
                "Rate",
                "Quanity",
                "Total Amount",
                "Date",
                "Vehicle",
                "Status",
              ]}
              data={pdfData}
              text={"Export As PDF"}
            />
          </div>
          <table class="table table-hover">
            <thead>
              <tr>
                <th className=" pl-4">ID</th>
                <th className="">Product</th>
                <th className="">Rate</th>
                <th className="">Quanity</th>
                <th className="">Total Amount</th>
                <th className="">Date</th>
                <th className="">Vehicle</th>
                <th className="">Status</th>
                {/* <th className="">Reciept</th> */}
              </tr>
            </thead>
            <tbody>
              {typeof itemsInCurrentPage !== "string" &&
                itemsInCurrentPage.map((item) => (
                  <tr key={uuidv4()}>
                    <td className="d-flex align-items-center">
                      <span className="ml-3">{item?.genTxId}</span>
                    </td>
                    <td>{item?.productId?.name}</td>
                    <td>{item.rate.toFixed(2)}</td>
                    <td>{item.quantity.toFixed(2)}</td>
                    <td>{item.total.toFixed(2)}</td>
                    <td>{item.date}</td>
                    <td>{item?.vehicleEmail}</td>
                    <td>{item?.payment ? "Creditted" : "Debitted"}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  Prev
                </button>
              </li>
              {[...Array(totalPages)].map((_, index) => (
                <li
                  key={index + 1}
                  className={`page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>

        {/* <EditModal open={editModal} setOpen={closeModal} /> */}
      </div>
      {loader && <Loader />}
    </>
  );
};

export default Pump;
