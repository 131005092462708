import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import api from '../../apis';
import { v4 as uuidv4 } from "uuid";
import Loader from '../loader';
import swal from "sweetalert";
import { useSelector } from "react-redux";
import VehicleRow from './vehicleRow';

import searchIcon from "../../images/search.svg";

function Pump() {
  const authedUser = useSelector(state => state.authedUser);

  const user = authedUser.authedUser;
  const [vehicles, setVehicle] = useState([]);

  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState('a');
  // const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [pumps, setPumps] = useState([]);


  const [company, setCompany] = useState([]);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const [data, setData] = React.useState({
    name: "",
    email: "",
    pump: "",
    company: "",
    phone: "",
    vehicleNumber: "",
    limit: "",
    vehicletype: "car",
    vehicledescription: "",
  });

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (user.type === "company") {
      console.log({
        ...data,
        pump: user.pump._id,
        company: user._id,
      });
      setData({
        ...data,
        pump: user.pump._id,
        company: user._id,
      });
    }

    if (user.type === "pump") {
      console.log({
        ...data,
        pump: user._id
      });
      setData({
        ...data,
        pump: user._id,
      });
    }
    if (user.type === "admin"){
      fetchPumps();
    }
  }, [show]);

  useEffect(() => {
    console.log("---", data);
  }, [data.company]);
  useEffect(() => {
    if (user.type === "admin") {
      fetchCompanies(data.pump);
    }
  }, [data.pump]);

  useEffect(() => {
    fetchvehicle();
    if (user.type === "pump") {
      fetchCompanies(user._id);
    }
  }, []);


  const fetchPumps = async () => {
    try {
      setLoader(true)
      const response = await api.getpumpsforcompany({ type: "pump" });
      if (response.success) {
        setPumps(response.message);
        setData({
          ...data,
          pump: response.message[0]._id,
        });
      }
      else {
        setData({
          ...data,
          pump: ""
        })
      }
      setLoader(false)
    } catch (e) {
      console.log(e);
      setLoader(false)
    }
  };

  const fetchCompanies = async (pump) => {
    try {
      setLoader(true)
      const response = await api.getcompanies({
        type: "company",
        pump: pump
      });
      console.log("fetchcompany", response);
      if (response.success) {
        setCompany(response.message);
        console.log("makaloda", response);
        setData({
          ...data,
          company: response.message[0]._id,
        });
      }
      else {
        setCompany([]);
      }

      setLoader(false)
    } catch (e) {
      console.log(e);
      setLoader(false)
    }
  };


  const validator = () => {
    console.log(data);
    if (!data.name) {
      return "Name is required";
    }
    if (!data.email) {
      return "Email is required";
    }
    // if (data.email && !helpers.isValidEmail(data.email)) {
    //   return "Email is not correctly formatted";
    // }
    if (!data.phone) {
      return "Phone is required";
    }
    let ph = data.phone.toString();

    if (ph[0] !== "0" || ph[1] !== "3") {
      return "Phone number must start from 03";
    }

    if (data.phone.length !== 11) {
      return "Phone is not correctly formatted";
    }
    if (data.phone && isNaN(data.phone)) {
      return "Phone is not correctly formatted";
    }
    if (!data.pump) {
      return "Pump is required";
    }
    if (!data.company) {
      return "Company is required";
    }
    if (!data.vehicleNumber) {
      return "Vehicle number is required";
    }
    if (!data.vehicletype) {
      return "Vehicle Type is required";
    }
    // if (!data.limit) {
    //   return "Limit is required";
    // }
    // if (data.limit && isNaN(data.limit)) {
    //   return "Limit is not correctly formatted";
    // }
    // if (data.limit < 0) {
    //   return "Limit must be greater than zero";
    // }

    return false;
  };

  const handlePumpChange = (event) => {
    setData({ ...data, pump: event.target.value });
    // fetchCompanies(event.target.value);
  };

  const handleNameChange = (event) => {
    setData({ ...data, name: event.target.value });
  };

  const handleEmailChange = (event) => {
    setData({ ...data, email: event.target.value });
  };

  const handleLimitChange = (event) => {
    setData({ ...data, limit: event.target.value });
    // setAvailableLimit(companyLimit - event.target.value);
  };

  const handlePhoneChange = (event) => {
    setData({ ...data, phone: event.target.value });
  };

  const handleVehicleNumberChange = (event) => {
    setData({ ...data, vehicleNumber: event.target.value });
  };

  const handleCompanyChange = (event) => {
    setData({ ...data, company: event.target.value, limit: 0 });
    // setAvailableLimit(0);
  };

  const handleVehicletypeChange = (event) => {
    setData({ ...data, vehicletype: event.target.value });
  };

  const addFunc = async () => {
    try {
      if (user.type == "admin" || user.type == "company" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true)
        if (validator()) {
          throw new Error(validator());
        } else {
          if (localStorage.getItem("token")) {

            data.limit = parseFloat(data.limit);

            function generateot() {

              var randomstring = '';
              var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
              for (var i = 0; i < 40; i++) {
                randomstring += characters.charAt(Math.floor(Math.random() * characters.length))
              }
              // console.log(randomstring);
              return randomstring
            }

            var onetimepass = generateot();

            const payload =
            {
              phone: data.phone,
              type: 'vehicle',
              email: data.email,
              name: data.name,
              vehicle_number: data.vehicleNumber,
              pump: data.pump,
              limit: data.limit,
              totalLimit: data.limit,
              otpass: onetimepass,
              company: data.company,
              companystaff: true,
              vehicletype: data.vehicletype,
            }

            let response = await api.registervehicle(payload);

            console.log(response);

            if (!response.success) {
              throw new Error(response.message);
            }

            await fetchvehicle();

            swal({
              title: "ADDED SUCCESSFULLY !",
              icon: "success",
            });
            handleClose();
            setData({});
          }
        }
        setLoader(false)

        setShow(false)
      }
      else {
        swal({
          title: "You are blocked due to some reason contact admin",
          icon: "warning",
        });
      }
    } catch (e) {
      console.log(e);
      // alert(e);
      swal({
        title: e,
        icon: "warning",
      });
      setLoader(false)
    }
  };


  const fetchvehicle = async (filter = "") => {
    try {
      // setLoader(true)
      let response;
      let filters = {
        email: {"$regex": filter, "$options": "i"}
      };
      if (user.type === 'pump') {
        filters = {
          ...filters,
          pump: user._id,
        };
      }
      if (user.type === 'company') {
        filters = {
          ...filters,
          company: user._id,
          pump: user.pump
        };
      }
      filters = { ...filters, type: "vehicle" };
      console.log(filters);
      response = await api.getallvehicle(filters);
      console.log(response);
      setVehicle(response.message);

      // setLoader(false)
    }
    catch (e) {
      console.log(e);
    }
  }
  const filteredVehicles = async () => {
    let filter = document.getElementById('vehicle-filter-email').value ? document.getElementById('vehicle-filter-email').value : "";
    fetchvehicle(filter);
  }

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col ">
            <div className=" card sortby-box br-10 border-transparent">
              {/* Card Header - Dropdown */}
              <div className="card-header br-10  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                  <h6>Filters</h6>
                  <div className="input-group">

                    <input type="text" id="vehicle-filter-email" className="form-control theme-bg-color border-0 small" placeholder="Enter Email" aria-label="Search" aria-describedby="basic-addon2" />
                    <div className="input-group-append">

                      <button onClick={filteredVehicles} className="btn search-btn pr-4" type="button">
                        <img src={searchIcon} className="w-15 mb-1" alt="serach icon" />
                      </button>
                    </div>
                  </div>
                </form>
                <a className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 fs-12" onClick={() => handleShow()}><span className="fs-14">+</span> Add New Vehicle</a>
              </div>

            </div>
            {/* table */}
            {/* <Table /> */}

            <div className="card sortby-box br-10 border-transparent mt-3">
              <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th className=" pl-4">Email</th>
                      <th className="">Title</th>
                      <th className="">Status</th>
                      <th className="">Vehicle Number</th>
                      <th className="">Phone</th>
                      <th className="">Limit</th>
                      {/* <th className="">Consumed</th> */}
                      {/* <th className="">Total Limit</th> */}
                      <th className="">Company</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      typeof vehicles !== "string" && vehicles
                        .map((item) => (
                          <VehicleRow key={uuidv4()} item={item} setLoader={setLoader} fetchvehicle={fetchvehicle} />
                        ))}
                  </tbody>
                </table>
              </div>

              {/* <EditModal open={editModal} setOpen={closeModal} /> */}


            </div>
          </div>

          {/* post load / bid column*/}
          {/* <LoadSecondRow /> */}

        </div>

      </div>
      <Modal show={show} onHide={handleClose} centered={true}>
        <Modal.Body>
          <div className="modalForm">
            <h3 className=''>Add New Vehicle</h3>
            <div className="form-group">
              <label>Vehicle Owner</label>
              <input type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Vehicle Owner" onChange={handleNameChange} />
            </div>
            <div className="form-group">
              <label>Email address</label>
              <input type="email" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email" onChange={handleEmailChange} />
              <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>
            <div className="form-group">
              <label>Vehicle Number</label>
              <input type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Vehicle Number" onChange={handleVehicleNumberChange} />
            </div>
            <div className="form-group">
              <label>Phone</label>
              <input type="number" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Phone" onChange={handlePhoneChange} />
            </div>
            {user.type === "admin" ? (
              <div className="form-group">
                <label>pump</label>
                <select className="form-control theme-bg-color border-0 " name="" id="" onChange={handlePumpChange}>
                  <option value="">Select pump</option>
                  {pumps.map((item) => (
                    <option value={item._id}>
                      {item.name}({item.email})
                    </option>
                  ))}
                </select>
              </div>
            ) : (<></>)}

            {(user.type === "pump" || user.type === "admin") ? (
              <div className="form-group">
                <label>Company</label>
                <select className="form-control theme-bg-color border-0 " name="" id="" onChange={handleCompanyChange}>
                  <option selected disabled value="">Select Company</option>
                  {
                    company.map((item) => (
                      <option value={item._id}>
                        {item.name}({item.email})
                      </option>
                    ))
                  }
                </select>
              </div>
            ) : (<></>)}


            <div className="form-group">
              <label>Type</label>
              <select className="form-control theme-bg-color border-0 " name="" id="" onChange={handleVehicletypeChange}>
                <option value="" disabled>Select pump</option>
                <option value="car">Car</option>
                <option value="bike">Bike</option>
              </select>
            </div>
            <div className="form-group">
              <label>Limit</label>
              <input type="number" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Limit" onChange={handleLimitChange} />
            </div>


            <div className='text-center subBtn'>
              <button class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10" onClick={addFunc} >Submit</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* /.container-fluid */}
      {loader && <Loader />}
    </>
  )
}


export default Pump