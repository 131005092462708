import React, { useState } from "react";
import SiteNav from "../siteNavbar/siteNavbar";
import modalImg from '../../../images/modalImg.png';
import { useNavigate, Navigate, NavLink } from "react-router-dom";

import Loader from '../../loader';
import swal from "sweetalert";
import api from "../../../apis";

export function Forgotpassword() {

    const [isVerified, setVerified] = useState(false);
    const [otpCode, setOtpCode] = useState("");
    const [loader, setLoader] = useState(false);


    const [data, setData] = useState({
        email: "",
        password: "",
        otp: "",
    })




    const handlepasswordchange = (event) => {
        setData({ ...data, email: event.target.value });
    }

    const handlepasschange = (event) => {
        setData({ ...data, password: event.target.value });
    }

    const handleotpchange = (event) => {
        setData({ ...data, otp: event.target.value });
    }



    const changepassF = async (e) => {
        try {
            setLoader(true);
            e.preventDefault();
            console.log("hello");

            // if (!data.email) {
            //     throw new Error("Email cannot be blank");
            // }

            //   if (state.email && !helpers.isValidEmail(state.email)) {
            //     return "Email is not correctly formatted";
            //   }

            const otp = await api.resetpass({ email: data.email });

            console.log(otp);

            if (!otp.otp) {
                throw new Error(otp.message);
            }

            console.log("responseotp", otp);
            const otpCode = otp.otp;

            setOtpCode(otpCode);



            swal({
                title: "We have mailed you one time passcode to your registered email, please enter it below and reset your password!",
                icon: "success",
            });

            setVerified(true);
            setLoader(false);
        } catch (e) {
            console.log(e);
            setLoader(false);


            swal({
                title: "User not found ",
                icon: "warning",
            });

        }
    };



    const verify_P = async (e) => {
        try {

            e.preventDefault();

            setLoader(true);
            if (!data.otp) {
                throw new Error("OTP cannot be blank");
            }
            if (!data.password) {
                throw new Error("Password cannot be blank");
            }
            if (data.password.length < 6) {
                throw new Error("Password cannot be less than 6 character");
            }

            const verify = await api.verifyotp({
                email: data.email,
                otp: data.otp,
                password: data.password,
            });

            console.log("verification", verify);
            if (!verify.success) {
                throw new Error("OTP verification failed!");
            }

            swal({
                title: "PASSWORD CHANGED SUCCESSFULLY !",
                icon: "success",
            });
            setLoader(false);
            setVerified(false);
            console.log("Reached");
            // history.push("/login");


        } catch (e) {
            // alert(e.message);
            setLoader(false);
            swal({
                title: "Error while verifying OTP",
                icon: "warning",
            });
        }
    };


    return (
        <div className="loginPage">
            <SiteNav />
            <div className='contactModal' >
                <div className="container">
                    <div className="row ai-center">
                        <div className="col-lg-7">
                            <div className="modalImg">
                                <img className="img-fluid" src={modalImg} alt="pump" />
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="mainContent">
                                <div className="heading text-center p-0">
                                    <h1>Reset Password</h1>
                                    <p className="mb-4">Find out more about pumpify.</p>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-md-12">
                                        <form>

                                            {
                                                !isVerified && (
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="exampleInputEmail1">Email</label>
                                                        <input name="email"
                                                            type="text"
                                                            className="form-control theme-bg-color "
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Your Email"
                                                            onChange={handlepasswordchange}
                                                        />
                                                    </div>

                                                )
                                            }

                                            {
                                                isVerified && (
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="exampleInputEmail1">OTP</label>
                                                        <input name="otp"
                                                            type="number"
                                                            className="form-control theme-bg-color "
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Your OTP"
                                                            onChange={handleotpchange}
                                                        />
                                                        <label htmlFor="exampleInputEmail1">New Password</label>
                                                        <input name="email"
                                                            type="password"
                                                            className="form-control theme-bg-color "
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Your New Password"
                                                            onChange={handlepasschange}
                                                        />
                                                    </div>
                                                )
                                            }


                                            {!isVerified && (
                                                <div className="text-center mt-4">
                                                    <button type="submit" className="btn sendBtn" onClick={changepassF}>Send OTP</button>
                                                </div>
                                            )}
                                            {isVerified && (
                                                <div className="text-center mt-4">
                                                    <button type="submit" className="btn sendBtn" onClick={verify_P}>Verify OTP</button>
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loader && <Loader />}

        </div>
    )
}
