import React from 'react';
import Modal from 'react-bootstrap/Modal';

export default function Detail({ item, show, setShow }){
  console.log("item", item)
  return(
    <Modal show={show} onHide={() => setShow(false)} centered={true}>
      <Modal.Body>
        <div className="modalForm">
          <h3 className=''>Details of Inventory</h3>

          <div className="form-group">
            <label>Pump</label>
            <select disabled={true} className="form-control theme-bg-color border-0 " name="" id="">
              <option selected={true} value={item._id}>
                {item?.pumpid?.name}({item?.pumpid?.email})
              </option>
            </select>
          </div>
          <div className="form-group">
            <label>Product</label>
            <select disabled={true} className="form-control theme-bg-color border-0 " name="" id="">
              <option selected={true} value={item._id}>
                {item?.productid?.name}
              </option>
            </select>
          </div>
          <div className="form-group">
            <label>Rate</label>
            <input value={item?.rate} type="number" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Rate" />
          </div>
          <div className="form-group">
            <label>Quantity</label>
            <input value={item?.quantity} type="number" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Quantity" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  

  );
}