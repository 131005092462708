import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux";
import swal from "sweetalert";
import api from "../../apis";

export default function EditPumpModal({ Editbankshow, EditbankhandleClose }) {
  const user = useSelector(state => state.authedUser.authedUser);

  const [data, setData] = React.useState({
    accountTitle: "",
    account_number: "",
    account_type: "",
    bankName: "",
    IBAN: "",
    city: "",
    branch: "",
  });


  const validator = () => {
    if (!data.name) {
      return "Name is required";
    }
    if (!data.unit) {
      return "Unit is required";
    }
    if (!data.sellingrate) {
      return "Selling rate is required";
    }
    return false;
  };


  const handletitleChange = (event) => {
    setData({ ...data, accountTitle: event.target.value });
  };
  const handleAccnumberChange = (event) => {
    setData({ ...data, account_number: event.target.value });
  };
  const handleBankNameChange = (event) => {
    setData({ ...data, bankName: event.target.value });
  };
  const handleIBANChange = (event) => {
    setData({ ...data, account_type: event.target.value });
  };
  const handlecityChange = (event) => {
    setData({ ...data, city: event.target.value });
  };
  const handlebranchChange = (event) => {
    setData({ ...data, branch: event.target.value });
  };


  const addBank = async () => {

    try {
      const payload = {
        pump: user?._id,
        bankName: data.bankName,
        accountNumber: data.account_number,
        accountTitle: data.accountTitle,
        accountType: data.account_type
      }
      const response = await api.managebankdetails(payload)
      if (response.success) {
        swal({
          title: response.message,
          icon: "success",
        });
      }
      console.log(payload);
    }
    catch (e) {
      console.log(e);
      swal({
        title: e,
        icon: "error",
      });
    }
  }

  return (
    <Modal show={Editbankshow} onHide={EditbankhandleClose} centered={true}>
      <Modal.Body>
        <div className="modalForm">
          <h3 className=''>Bank Details</h3>
          <div className="form-group">
            <label htmlFor="edit-pump-name">Account Title</label>
            <input defaultValue={user?.accountTitle} onChange={handletitleChange} type="text" className="form-control theme-bg-color border-0 small" id="edit-pump-name" aria-describedby="emailHelp" placeholder="Enter Title" />
          </div>
          <div className="form-group">
            <label htmlFor="edit-pump-name">Account Number</label>
            <input defaultValue={user?.accountNumber} onChange={handleAccnumberChange} type="number" className="form-control theme-bg-color border-0 small" id="edit-pump-name" aria-describedby="emailHelp" placeholder="Enter Account Number" />
          </div>
          <div className="form-group">
            <label htmlFor="edit-pump-name">Bank Name</label>
            <input defaultValue={user?.bankName} onChange={handleBankNameChange} type="text" className="form-control theme-bg-color border-0 small" id="edit-pump-name" aria-describedby="emailHelp" placeholder="Enter Bank Name " />
          </div>
          <div className="form-group">
            <label htmlFor="edit-pump-name">Account Type</label>
            <input defaultValue={user?.accountType} onChange={handleIBANChange} type="text" className="form-control theme-bg-color border-0 small" id="edit-pump-name" aria-describedby="emailHelp" placeholder="Enter Account Type" />
          </div>

          <div className='text-center subBtn'>
            <button
              class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10" onClick={addBank}>Submit</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}