import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import api from "../../apis";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import helpers from "../../helpers/helpers";

export default function VehicleEditModal({
  show,
  setShow,
  fetchCurrent,
  fetchvehicle,
}) {
  const user = useSelector((state) => state.authedUser.authedUser);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    vehicleNumber: "",
    limit: "",
    vehicletype: "",
    vehicledescription: "",
  });

  useEffect(() => {
    fetchData();
  }, [show]);

  const fetchData = async () => {
    setData({
      ...data,
      _id: fetchCurrent._id,
      name: fetchCurrent.name,
      email: fetchCurrent.email,
      phone: fetchCurrent.phone,
      vehicleNumber: fetchCurrent.vehicle_number,
      limit: fetchCurrent.limit,
      vehicletype: fetchCurrent.vehicletype,
      vehicledescription: fetchCurrent.vehicledescription,
    });
  };

  const validator = () => {
    if (!data.name) {
      return "Name is required";
    }
    if (!data.email) {
      return "Email is required";
    }
    if (data.email && !helpers.isValidEmail(data.email)) {
      return "Email is not correctly formatted";
    }
    if (!data.phone) {
      return "Phone is required";
    }
    let ph = data.phone.toString();

    if (ph[0] !== "0" || ph[1] !== "3") {
      return "Phone number must start from 03";
    }

    if (!data.vehicletype) {
      return "Vehicle Type is required";
    }
    if (data.phone.length !== 11) {
      return "Phone is not correctly formatted";
    }
    if (data.phone && isNaN(data.phone)) {
      return "Phone is not correctly formatted";
    }
    if (!data.vehicleNumber) {
      return "Vehicle number is required";
    }
    if (!data.limit) {
      return "Limit is required";
    }
    if (data.limit < 0) {
      return "Limit must be greater than zero";
    }
    if (data.limit && isNaN(data.limit)) {
      return "Limit is not correctly formatted";
    }
    if (data.limit < parseInt(fetchCurrent.consumed)) {
      return "Vehcile limit should be less than or equal to company limit";
    }

    return false;
  };

  const handleClose = async () => {
    await fetchData();
    setShow(false);
  };

  const handleNameChange = (event) => {
    setData({ ...data, name: event.target.value });
  };

  const handleEmailChange = (event) => {
    setData({ ...data, email: event.target.value });
  };
  const handleDescriptionChange = (event) => {
    setData({ ...data, vehicledescription: event.target.value });
  };

  const handleLimitChange = (event) => {
    setData({ ...data, limit: event.target.value });
  };

  const handlePhoneChange = (event) => {
    setData({ ...data, phone: event.target.value });
  };

  const handleVehicleNumberChange = (event) => {
    setData({ ...data, vehicle_number: event.target.value });
  };

  const handleVehicletypeChange = (event) => {
    setData({ ...data, vehicletype: event.target.value });
    // setAvailableLimit(0);
  };

  const saveDetails = async () => {
    try {
      if (
        user.type == "admin" ||
        user.type == "company" ||
        (user.type == "pump" && user.canUseAll)
      ) {
        setLoader(true);
        if (validator()) {
          throw new Error(validator());
        } else {
          if (localStorage.getItem("token")) {
            let company_details = await fetchCompany();
            console.log(company_details);
            if (!company_details[0]) {
              throw new Error("Error while updating vehicle");
            }

            const diff = parseInt(fetchCurrent.limit) - parseInt(data.limit);
            let limitT = 0;

            if (diff < 0) {
              limitT = parseInt(company_details[0].limit) - Math.abs(diff);
              console.log(limitT);
            }
            if (diff === 0) {
              limitT = parseInt(company_details[0].limit);
              console.log(limitT);
            }
            if (diff > 0) {
              limitT = parseInt(company_details[0].limit) + Math.abs(diff);
              console.log(limitT);
            }

            let cLimit =
              parseInt(company_details[0].limit) + parseInt(fetchCurrent.limit);
            console.log("cLimit", cLimit);
            // if (( parseInt(Math.abs(data.limit)) > cLimit)) {
            //   throw new Error(
            //     "Comapany limit is less than vehicle limit, you can only increase: " +
            //       cLimit
            //   );
            // }
            console.log(
              { user: { _id: company_details[0]._id } },
              {
                limit: limitT,
                _id: company_details[0]._id,
              }
            );
            let companyUpdate = await api.update_company({
              user: { _id: company_details[0]._id },
              update: {
                limit: limitT,
                _id: company_details[0]._id,
              },
            });

            data.limit = Math.abs(data.limit - fetchCurrent.consumed);
            data.totalLimit = Math.abs(data.limit - fetchCurrent.consumed);
            console.log("data", data);
            let response = await api.update_vehicle({
              user: { _id: data._id },
              update: data,
            });

            if (!response.success) {
              throw new Error(response.message);
            }

            await fetchData();
            await fetchvehicle();
            handleClose();
            // alert("Update Successfull.");
            swal({
              title: "UPDATED SUCCESSFULLY !",
              // text: "You clicked the button!",
              icon: "success",
            });
          }
        }
        setShow(false);
        setLoader(false);
      } else {
        swal({
          title: "You are blocked due to some reason contact admin",
          icon: "warning",
        });
      }
    } catch (e) {
      console.log(e);
      // alert(e);
      swal({
        title: e,

        icon: "warning",
      });
      setLoader(false);
    }
  };

  const fetchCompany = async () => {
    if (localStorage.getItem("token")) {
      let response = await api.getcompanies({
        email: fetchCurrent.company.email,
      });
      console.log(response);
      return response.message;
    }
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered={true}>
      <Modal.Body>
        <div className="modalForm">
          <h3 className="">Vehicle Details</h3>
          <div className="form-group">
            <label>Vehicle Owner</label>
            <input
              value={data?.name}
              onChange={handleNameChange}
              type="text"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Email address</label>
            <input
              value={data?.email}
              onChange={handleEmailChange}
              type="email"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
            <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>
          <div className="form-group">
            <label>Vehicle Number</label>
            <input
              value={data?.vehicleNumber}
              onChange={handleVehicleNumberChange}
              type="text"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input
              value={data?.phone}
              onChange={handlePhoneChange}
              type="number"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Limit</label>
            <input
              value={data?.limit}
              onChange={handleLimitChange}
              type="number"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>

          <div className="form-group">
            <label>Description</label>
            <input
              value={data?.vehicledescription}
              onChange={handleDescriptionChange}
              type="text"
              className="form-control theme-bg-color border-0 small"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>

          <div className="form-group">
            <label htmlFor="vehicle-type-edit">Vehicle Type</label>
            <select
              className="form-control theme-bg-color border-0 "
              onChange={handleVehicletypeChange}
              name="vehicle-type-edit"
              id="vehicle-type-edit"
            >
              <option value="car" selected={data?.vehicletype === "car"}>
                Car
              </option>
              <option value="bike" selected={data?.vehicletype === "bike"}>
                Bike
              </option>
            </select>
          </div>

          <div className="text-center subBtn">
            <button
              onClick={saveDetails}
              class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10"
            >
              Submit
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
