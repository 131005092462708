import axios from "axios";

const axiosObject = {};

axiosObject.post = async function (url, body, config = {}) {
    try {
        const response = await axios.post(url, body, { withCredentials: false, ...config });
        return response.data;
    } catch (e) {
        console.log(e);
        const message = e.response.data;
        console.log(message);
        throw new Error(message.message);
    }
};



axiosObject.patch = async function (url, body, config = {}) {
    try {
        const response = await axios.patch(url, body, { withCredentials: false, ...config });
        return response.data;
    } catch (e) {
        const message = e.response.data;
        throw new Error(message);
    }
};

axiosObject.delete = async function (url, body) {
    try {
        const response = await axios.delete(url, { data: body });
        return response.data;
    } catch (e) {
        const message = e.response.data;
        throw new Error(message);
    }
};

axiosObject.get = async function (url, params, config) {
    try {
        const response = await axios.get(url, { params: params, ...config });
        return response.data;
    } catch (e) {
        const message = e.response.data;
        throw new Error(message);
    }
};

export default axiosObject;
