import React, { useState } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import logo from "../../images/logo.png";

const PDF = (props) => {
  const { text, data, rows, total } = props;
  const [date] = useState(new Date());

  const toPdf = async () => {
    try {
      if (!data.length) {
        throw new Error("No data to export");
      }
  
      const doc = new jsPDF({
        format: [400, 400],
      });
  
      // Logo
      doc.addImage(logo, "PNG", 20, 20, 40, 40);
  
      // Title
      doc.setFont("Calibri", "bold");
      doc.setFontSize(18);
      doc.setTextColor(14, 3, 64);
      doc.text("Pumpify Invoice", 70, 40);
  
      // Date
      doc.setFont("Calibri", "normal");
      doc.setFontSize(12);
      doc.setTextColor(14, 3, 64);
     
  
      doc.autoTable({
        margin: { top: 90, left: 20, bottom: 30 },
        head: [rows],
        body: data,
        theme: "grid",
        headStyles: {
          fillColor: [14, 3, 64],
          textColor: 255,
          fontStyle: "bold",
        },
        styles: {
          fontSize: 10,
        },
      });
  
      // Total
      const total = data.reduce((acc, row) => {
        return acc + parseFloat(row[3]);
      }, 0);
      doc.setFont("Calibri", "bold");
      doc.setFontSize(12);
      doc.setTextColor(14, 3, 64);
  
      // Signature
      doc.setFont("Calibri", "normal");
      doc.setFontSize(12);
      doc.setTextColor(14, 3, 64);
      doc.text("Signature: ________________________", 20, doc.lastAutoTable.finalY + 40);
  
      // Generation date
      doc.setFont("Calibri", "normal");
      doc.setFontSize(10);
      doc.setTextColor(14, 3, 64);
      doc.text(
        `Generated on: ${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`,
        20,
        doc.lastAutoTable.finalY + 50
      );
  
      doc.save(
        `pumpify-${
          date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear()
        }.pdf`
      );
  
      window.open(doc.output("bloburl"), "_blank");
    } catch (e) {
      alert(e.message);
    }
  };

  return <p className="btn btn-primary" onClick={toPdf}>{text}</p>;
};

export default PDF;
