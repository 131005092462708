import React from "react";
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux";
import swal from "sweetalert";
import api from "../../apis";

export default function EditPumpModal({ editshow, edithandleClose, defaultData, setLoader, updatePump }) {
  const user = useSelector(state => state.authedUser.authedUser);

  const validator = (data) => {
    if (!data.name) {
      return "Name is required";
    }
    if (!data.address) {
      return "Address is required";
    }
    if (!data.owner_phone) {
      return "Phone is required";
    }
    if (data.owner_phone && isNaN(data.owner_phone)) {
      return "Phone is not correctly formatted";
    }
    let ph = data.owner_phone.toString();

    if (ph[0] !== "0" || ph[1] !== "3") {
      return "Phone number must start from 03";
    }

    if (data.owner_phone.length !== 11) {
      return "Phone is not correctly formatted";
    }
    return false;
  };

  const editDetails = async () => {
    if (user.type === 'admin'){
      try{
        setLoader(true);
        let name = document.getElementById("edit-pump-name").value;
        let address = document.getElementById("edit-pump-address").value;
        let omc = document.getElementById("edit-pump-omc").value;
        let owner_phone = document.getElementById("edit-pump-owner-phone").value;
        let manager_phone = document.getElementById(
          "edit-pump-manager-phone"
        ).value;
        let city = document.getElementById("edit-pump-city").value;
        let state = document.getElementById("edit-pump-state").value;
  
        let data = {
          name,
          // email: defaultData?.email,
          address,
          omc,
          owner_phone,
          manager_phone,
          city,
          state
        };
  
        let error = validator(data);
        if (error) {
          throw new Error(error);
        }
        else {
          let api_res = await api.updatepump({ user: { _id: defaultData._id }, update: data });
          if (api_res.success) {
            setLoader(false);
            swal({
              title: "Pump Updated Successfully",
              icon: "success",
            });
            updatePump(api_res.message);
            edithandleClose();
          }
  
          else {
            setLoader(false);
            swal({
              title: api_res.message,
              icon: "error",
            });
            edithandleClose();
          }
        }
      } catch (e) {
        console.log(e);
        // alert(e);
        swal({
          title: e,
          icon: "warning",
        });
  
        setLoader(false);
      }
    }
    else{
      swal({
        title: "You are blocked due to some reason contact admin",
        icon: "warning",
      });
    }
  }

  return (
    <Modal show={editshow} onHide={edithandleClose} centered={true}>
      <Modal.Body>
        <div className="modalForm">
          <h3 className=''>Edit Pump</h3>
          <div className="form-group">
            <label htmlFor="edit-pump-name">Name</label>
            <input defaultValue={defaultData?.name} type="text" className="form-control theme-bg-color border-0 small" id="edit-pump-name" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>
          <div className="form-group">
            <label htmlFor="edit-pump-email-add">Email address</label>
            <input disabled defaultValue={defaultData?.email} type="email" className="form-control theme-bg-color border-0 small" id="edit-pump-email-add" aria-describedby="emailHelp" placeholder="Enter email" />
            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
          </div>
          <div className="form-group">
            <label htmlFor="edit-pump-address">Address</label>
            <input defaultValue={defaultData?.address} type="text" className="form-control theme-bg-color border-0 small" id="edit-pump-address" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>

          <div className="form-group">
            <label htmlFor="edit-pump-omc">Oil Marketing Company</label>
            <input defaultValue={defaultData?.omc} type="text" className="form-control theme-bg-color border-0 small" id="edit-pump-omc" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>

          <div className="form-group">
            <label htmlFor="edit-pump-owner-phone">PTCL</label>
            <input defaultValue={defaultData?.manager_phone} type="number" className="form-control theme-bg-color border-0 small" id="edit-pump-owner-phone" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>

          <div className="form-group">
            <label htmlFor="edit-pump-manager-phone">Contact Number</label>
            <input defaultValue={defaultData?.owner_phone} type="text" className="form-control theme-bg-color border-0 small" id="edit-pump-manager-phone" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>

          <div className="form-group">
            <label htmlFor="edit-pump-city">City</label>
            <input defaultValue={defaultData?.city} type="text" className="form-control theme-bg-color border-0 small" id="edit-pump-city" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>

          <div className="form-group">
            <label htmlFor="edit-pump-state">State</label>
            <input defaultValue={defaultData?.state} type="text" className="form-control theme-bg-color border-0 small" id="edit-pump-state" aria-describedby="emailHelp" placeholder="Enter email" />
          </div>

          <div className='text-center subBtn'>
            <button onClick={editDetails} class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10">Submit</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}