import React,{useEffect,useState} from 'react';
import { BrowserRouter as Router, Switch, Route,useParams } from "react-router-dom";
import uploadIcon  from "../../images/uploadIcon.svg";
import plus from "../../images/plus.svg";
import "./AssignLoadForm.css";

import { NavLink } from "react-router-dom";



function AssignLoadForm() {


    return(
      <>
      
      <div className="row mx-0 jc-center">
      <div className="col-xl-7 col-lg-9 col-11">
            <section className="AssignLoadFormSec">
                <form >
                    {/* text input */}
                    <div className="row mx-0 mb-2">
                        <div className="col-6 mb-4">
                            <input type="text" name="" id="" placeholder="Text" className="form-control theme-bg-color border-0 small" />
                        </div>
                        <div className="col-6 mb-4">
                            <input type="text" name="" id="" placeholder="Text" className="form-control theme-bg-color border-0 small" />
                        </div>
                        <div className="col-6 mb-4">
                            <input type="text" name="" id="" placeholder="Text" className="form-control theme-bg-color border-0 small" />
                        </div>
                        <div className="col-6 mb-4">
                            <input type="text" name="" id="" placeholder="Text" className="form-control theme-bg-color border-0 small" />
                        </div>
                        <div className="col-6 mb-4">
                            <input type="text" name="" id="" placeholder="Text" className="form-control theme-bg-color border-0 small" />
                        </div>
                        <div className="col-6 mb-4">
                            <input type="text" name="" id="" placeholder="Text" className="form-control theme-bg-color border-0 small" />
                        </div>
                        <div className="col">
                            <div className="bb1pxLight"></div>
                        </div>
                    </div>
                    {/* file input */}
                    <div className="row mx-0 mt-4">
                        <h6 className="col">Attach Files</h6>
                    </div>
                    <div className="row mx-0 attachFile">
                        <div className="col-4">
                            <label>
                                <img src={uploadIcon} alt="upload" />
                                <input type="file" name="rateConfirmation" />
                            </label>
                            <div className="text-center">Rate Confirmation</div>
                        </div>
                        <div className="col-4">
                            <label>
                                <img src={uploadIcon} alt="upload" />
                                <input type="file" name="BillOfLaiden" />
                            </label>
                            <div className="text-center">Bill Of Laiden</div>
                        </div>
                        <div className="col-4">
                            <label>
                                <img src={uploadIcon} alt="upload" />
                                <input type="file" name="Proof Of Delivery" />
                            </label>
                            <div className="text-center">Proof Of Delivery</div>

                        </div>
                    </div>
                </form>
            </section>
            </div>
            </div>
         
      </>
    )
  }

  export default AssignLoadForm