import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import Loader from "../loader";
import swal from "sweetalert";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import api from "../../apis";
import { Lightbox } from "react-modal-image";

import HRimage from "../imageupload/hr";
// import EditModal from "./edit";
import moreIcon from "../../images/moreIcon.svg";
import Add from "./add";

function Pump() {
  const [show, setShow] = useState(false);
  const user = useSelector((state) => state.authedUser.authedUser);

  const [imgdata, setImgdata] = useState({});
  const [loader, setLoader] = useState(false);
  // const [open, setOpen] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [company, setCompany] = useState([]);
  const [dueAmount, setDueAmount] = useState(0);
  const [data, setData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [newData, setNewData] = useState({
    amount: "",
  });

  const fetchAllUsers = async () => {
    try {
      setLoader(true);
      let response;
      response = await api.get_payments({});
      console.log("paymentss", response);
      setData(response);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const fetchAllCompany = async () => {
    try {
      setLoader(true);
      const response = await api.getcompanies({
        type: "company",
      });
      console.log(response);
      if (response.success) {
        setCompany(response.message);
      }
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchAllCompany();
    fetchAllUsers();
  }, []);

  const validator = () => {
    console.log(imgdata);
    if (!newData.amount || isNaN(newData.amount)) {
      return "Amount is not correct";
    }
    if (!imgdata.hrimageData) {
      return "Reciept is required";
    }
    if (Object.keys(selectedCompany).length === 0) {
      return "Please select company";
    }
  };

  const addReceipt = async () => {
    try {
      setLoader(true);
      if (validator()) {
        throw new Error(validator());
      } else {
        if (localStorage.getItem("token")) {
          console.log(data);

          const userLimitStatus = selectedCompany.billing_type;
          // total consumed actual limit
          const consumed = selectedCompany.consumed;
          // total actual limit
          const totalLimit = selectedCompany.totalLimit;
          // total temp limit
          const tempLimit = selectedCompany.temp_limit;
          // tota;l consumed temp limit
          const consumedTempLimit = selectedCompany.consumedTempLimit;
          // total remainging actual limit
          const remaining = parseFloat(totalLimit) - parseFloat(consumed);
          // total remaining temp limit
          const remainingTempLimit =
            parseFloat(tempLimit) - parseFloat(consumedTempLimit);
          // total entered amount by user
          const enterdAmount = parseFloat(newData.amount);
          // Total actual amount to pay if any
          const totalToPayLimit =
            parseFloat(totalLimit) - parseFloat(remaining);
          // Total temp amount to pay if any
          const totalToPayTempLimit =
            parseFloat(tempLimit) - parseFloat(remainingTempLimit);
          // Total amount to pay if any
          const totalPay = totalToPayLimit + totalToPayTempLimit;
          // Payload to update
          let payload = {};

          // if (userLimitStatus == "Advance") {
          if (!newData.amount) {
            swal({
              title: "Amount to pay is required",
              icon: "error ",
            });

            setLoader(false);
            return;
          } else {
            if (parseFloat(newData.amount) > totalPay) {
              //  check if user want to increase limit
              // const toIncrease = parseFloat(data.amount) - totalPay;
              payload.totalLimit =
                parseFloat(newData.amount) + parseFloat(remaining);
              payload.limit =
                parseFloat(newData.amount) + parseFloat(remaining);
              payload.consumed = 0;
              payload.consumedTempLimit = 0;
              payload.temp_limit = 0;
            } else if (parseFloat(newData.amount) < totalPay) {
              // checking if user paying less amount than bill so open limited
              const remainingBalNow = totalPay - parseFloat(newData.amount);
              const usable = totalPay - remainingBalNow - totalToPayTempLimit;

              if (parseFloat(newData.amount) <= totalToPayTempLimit) {
                payload.consumedTempLimit =
                  consumedTempLimit - parseFloat(newData.amount);
                // payload.temp_limit = tempLimit - parseFloat(data.amount);

                setLoader(false);
              } else {
                payload.limit = usable;
                // consume amt prev - temp_limit - entered amount
                // payload.consumed =  parseFloat(data.amount) + remainingBalNow;
                payload.consumed =
                  consumed - (parseFloat(newData.amount) - consumedTempLimit);
                payload.consumedTempLimit = 0;
                payload.temp_limit = 0;
              }
            } else if (parseFloat(newData.amount) == totalPay) {
              // if paying limit is equal to due

              if (parseFloat(newData.amount) <= totalToPayTempLimit) {
                payload.consumedTempLimit =
                  consumedTempLimit - parseFloat(newData.amount);
                // payload.temp_limit = tempLimit - parseFloat(data.amount);

                setLoader(false);
                return;
              }

              payload.totalLimit =
                parseFloat(newData.amount) + parseFloat(remaining);
              payload.limit =
                parseFloat(newData.amount) + parseFloat(remaining);
              payload.consumed = 0;
              payload.consumedTempLimit = 0;
              payload.temp_limit = 0;
            }

            payload._id = selectedCompany._id;

            const update_companies_f = await api.update_company({
              user: { _id: selectedCompany._id },
              update: payload,
            });
            const recieptPayload = {
              company: selectedCompany._id,
              collector: user._id,
              amount: newData.amount,
              date: new Date().toLocaleDateString(),
              receiptImg: imgdata.hrimageData,
              receiptFormat: imgdata.hrimageFormat,
            };
            let response = await api.create_receipt(recieptPayload);


            console.warn(selectedCompany)
            const payloadTrx = {
              company: selectedCompany._id,
              total: newData.amount,
              pump: user?._id,
            }
  
            const responseTrx = await api.createPaymentTrx(payloadTrx);

            fetchAllCompany();
            swal({
              title: "Paid",
              icon: "sucess ",
            });
          }
          // } else if (userLimitStatus == "Credit") {
          //   console.log(consumed)
          // }
          fetchAllUsers();
          setShow(false);
          setNewData({ amount: "" });
          setImgdata({});

        }
      }
      setLoader(false);
    } catch (e) {
      console.log(e);
      // alert(e);
      swal({
        title: e,

        icon: "warning",
      });
      setLoader(false);
    }
  };

  const newCompanyForNewReceipt = (company_id) => {
    // total consumed actual limit
    const consumed = company_id.consumed;
    // total actual limit
    const totalLimit = company_id.totalLimit;
    // total temp limit
    const tempLimit = company_id.temp_limit;
    // tota;l consumed temp limit
    const consumedTempLimit = company_id.consumedTempLimit;
    // total remainging actual limit
    const remaining = parseFloat(totalLimit) - parseFloat(consumed);
    // total remaining temp limit
    const remainingTempLimit =
      parseFloat(tempLimit) - parseFloat(consumedTempLimit);
    // Total actual amount to pay if any
    const totalToPayLimit = parseFloat(totalLimit) - parseFloat(remaining);
    // Total temp amount to pay if any
    const totalToPayTempLimit =
      parseFloat(tempLimit) - parseFloat(remainingTempLimit);
    // Total amount to pay if any
    const totalPay = totalToPayLimit + totalToPayTempLimit;
    setDueAmount(totalPay);
    setSelectedCompany(company_id);
  };

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col ">
            <div className=" card sortby-box br-10 border-transparent">
              {/* Card Header - Dropdown */}
              <div className="card-header br-10  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                <div className="d-flex ">
                  <h6 className="my-auto chart-heading color-light mr-3">
                    Sort by:
                  </h6>
                  <div class="dropdown">
                    <button
                      class="btn dropdown-toggle border1-light w-190 py-1 px-3 d-flex justify-content-between"
                      type="button"
                      id="dropdownMenu2"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="chart-heading color-light ">
                        Start Date
                      </span>
                      <img
                        src="/static/media/dropdown-icon.728bb38c.svg"
                        class="w-9 ml-1 mt-1"
                        alt="dropdown icon"
                      ></img>
                    </button>
                    <div
                      class="dropdown-menu zi-1"
                      aria-labelledby="dropdownMenu2"
                    >
                      <button class="dropdown-item" type="button">
                        Start Date
                      </button>
                    </div>
                  </div>
                </div>
                <a
                  className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 fs-12"
                  onClick={() => handleShow()}
                >
                  <span className="fs-14">+</span> Add New Receipt
                </a>
              </div>
            </div>
            {/* table */}
            <Table receipts={data} fetchData={fetchAllUsers} />
          </div>

          {/* post load / bid column*/}
          {/* <LoadSecondRow /> */}
        </div>
      </div>
      {/* <Add
          open={show}
          setOpen={setShow}
          fetchData={fetchAllUsers}
          fetchAllCompany={fetchAllCompany}
        /> */}

      <Modal show={show} onHide={handleClose} centered={true}>
        <Modal.Body>
          <div className="modalForm">
            <h3 className="">Add New Receipt</h3>

            <div className="form-group">
              <label>Company</label>
              <select
                onChange={(e) =>
                  newCompanyForNewReceipt(JSON.parse(e.target.value))
                }
                className="form-control theme-bg-color border-0 "
                name=""
                id=""
              >
                <option selected disabled>
                  Select Company
                </option>
                {company.map((c) => (
                  <option value={JSON.stringify(c)}>{c.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="paid-amt">Amount</label>
              <input
                value={newData?.amount}
                onChange={(e) =>
                  setNewData({
                    amount: e.target.value ,
                  })
                }
                type="number"
                id="paid-amt"
                className="form-control theme-bg-color border-0 small"
                aria-describedby="emailHelp"
                placeholder="Enter Amount Paid"
              />
            </div>
            <div className="form-group">
              <label htmlFor="due-amt">Due Amount</label>
              <input
                value={dueAmount}
                type="number"
                id="due-amt"
                className="form-control theme-bg-color border-0 small"
                aria-describedby="emailHelp"
                placeholder="Enter Due Amount"
              />
            </div>

            <div className="form-group">
              <label>Upload Image</label>
              <HRimage setImgdata={setImgdata} />
            </div>
            <div className="text-center subBtn">
              <button
                onClick={addReceipt}
                class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10"
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* /.container-fluid */}
    </>
  );
}

const Table = ({ receipts, fetchData }) => {
  const [downloadImg, setDownloadImg] = useState("");

  const detailhandleClose = () => {
    setDownloadImg("");
  };
  const detailhandleShow = (item) => {
    console.log(
      item,
      process.env.REACT_APP_SERVER_BASE_URL +
        "/uploadedImages/img_" +
        item?.receiptImg +
        "." +
        item?.receiptFormat
    );
    setDownloadImg(
      process.env.REACT_APP_SERVER_BASE_URL +
        "/uploadedImages/img_" +
        item?.receiptImg +
        "." +
        item?.receiptFormat
    );
  };

  return (
    <div className="card sortby-box br-10 border-transparent mt-3">
      <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Amount</th>
              <th scope="col">Date</th>
              <th scope="col">Collector</th>
              <th scope="col">Company</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {receipts.map((receipt, index) => (
              <tr>
                <td scope="row">{receipt.amount}</td>
                <td scope="row">{receipt?.date}</td>
                <td scope="row">{receipt?.collector?.email}</td>
                <td scope="row">{receipt?.company?.email}</td>
                <td>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Button {...bindTrigger(popupState)}>
                          <img src={moreIcon} alt="" />
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem
                            onClick={() => {
                              popupState.close();
                              detailhandleShow(receipt);
                            }}
                          >
                            View Receipt
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* <EditModal open={editModal} setOpen={closeModal} /> */}

      {downloadImg && (
        <Lightbox
          medium={downloadImg}
          large={downloadImg}
          alt="Hello World!"
          onClose={detailhandleClose}
        />
      )}
    </div>
  );
};
export default Pump;
