import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux";
import swal from "sweetalert";
import api from "../../apis";

export default function EditPumpModal({ sheetshow, sheethandleClose }) {
  const user = useSelector(state => state.authedUser.authedUser);
  const [productdata, setproductData] = useState([]);

  const [data, setData] = React.useState({
    nozelId: "",
    endReading: ""
  });

  const [nozels, setNozels] = useState([])
  const [products, setProducts] = useState([])

  useEffect(() => {
    fetchnozels();
    fetchProducts();
    fetchPumpProducts()
  }, [])
  
  const fetchProducts = async () => {
    let p = await api.getproducts({pump: user?.pump});
    console.log("asdfpqrst", p);
  }

  const handletitleChange = (event) => {
    setData({ ...data, endReading: event.target.value });
  };
  const handleAccnumberChange = (event) => {
    setData({ ...data, nozelId: event.target.value });
  };

  const fetchnozels = async () => {
    try {
      const payload = {
        pump: user?._id
      }
      const response = await api.getnozels(payload)
      console.log("nozels", response);
      setNozels(response.message)
    }
    catch (e) {
      console.log(e);
    }
  }
  const addBank = async () => {

    try {
      let p = [];
      nozels.forEach(n => {
        if (document.getElementById(`nozel-end-${n.nozelId}`).value){
          let payld = {
            pump: user?._id,
            nozelId: n._id, 
            endReading: document.getElementById(`nozel-end-${n.nozelId}`).value
          };
          p.push(payld);
        }
        else{
          alert('Fill all fields');
        }
      })
      // const payload = {
      //   pump: user?._id,
      //   nozelId: data.nozelId,
      //   endReading: data.endReading
      // }

      const response = await api.sheetmanagement(p)
      if (response.success) {
        swal({
          title: response.message,
          icon: "success",
        });
      }
      // console.log(payload);
    }
    catch (e) {
      console.log(e);
      swal({
        title: e,
        icon: "error",
      });
    }
  }

  const fetchPumpProducts = async (filter = "") => {
    try {
      let permission = {
        filter: {"$regex": filter, "$options": "i"}
      };
      if (user.type === 'pump') {
        permission = {
          ...permission,
          pump: user._id
        }
      };
      console.log(permission);
      const response = await api.readproducts(permission);
      console.log(response.message);
      setproductData(response.message);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Modal show={sheetshow} onHide={sheethandleClose} centered={true}>
      <Modal.Body>
        <div className="modalForm">
          <h3 className=''>Sheet Management</h3>
          <div className="form-group">
            {nozels.map((item, i) => {
              return(
                <>
                  <label htmlFor={`nozel-end-${item?.nozelId}`}>Nozel # {item?.nozelId} Ending Reading</label>
                  <input type="number" className="form-control theme-bg-color border-0 small" id={`nozel-end-${item?.nozelId}`} aria-describedby="emailHelp" placeholder="Enter End Reading" />
                  {/* {user?.virtualPayments.map((vp, index) => {
                    return(
                      <>
                        <label htmlFor={`virual-payment-${vp}-${item?.nozelId}`}>Through {vp}: (litres)</label>
                        <input type="number" className="form-control theme-bg-color border-0 small" id={`virual-payment-${vp}-${item?.nozelId}`} aria-describedby="emailHelp" placeholder="Enter Litres" />
                      </>
                    );
                  })} */}
                  <hr style={{borderTop: '1px solid black'}} />
                </>
              );
            })}
            {/* <div className="form-group">
              <label>Nozels</label>
              <select className="form-control theme-bg-color border-0 " onChange={handleAccnumberChange} name="" id="" >
                <option value="" disabled selected>Select Nozel</option>
                {typeof nozels !== "string" &&
                  nozels.map((item) =>
                  (
                    <option value={item._id}>
                      {item.nozelId}
                    </option>
                  ))}
              </select>
            </div> */}
                {productdata.map((pItem,key)=>(
                  <>
                    {user?.virtualPayments.map((vp, index) => {
                      return(
                        <>
                          <label htmlFor={``}>{pItem?.name} sold on {vp} (litres)</label>
                          <div className="d-flex pb-2">
                            <input type="number" className="form-control theme-bg-color border-0 small" id={``} aria-describedby="emailHelp" placeholder="Enter Litres" />
                            <button  class=" addbutton btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 mt-0 ">Add</button>
                          </div>
                        </>
                      );
                    })}
                  </>
                ))}
          </div>


          <div className='text-center subBtn'>
            <button
              class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10" onClick={addBank}>Submit</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}