import React, { useState } from 'react';
import Detail from './detail';
import Edit from './edit';
import rightArrow from "../../images/right-arrow.svg";


import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import edit from "../../images/edit.svg";

import moreIcon from '../../images/moreIcon.svg'
import api from '../../apis';

export default function Row({ item, setLoader, fetchData }){
  const [editShow, setEditShow] = useState(false);
  const [detailShow, setDetailShow] = useState(false);

  const deleteUser = async (item) => {
    try {
      setLoader(true);
      let data = item;
      const response = await api.deleteinventory(data);
      console.log(data);
      if (!response) {
        throw new Error("Error while deleting");
      }
      fetchData();
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };


  return(
    <tr >
      <td className="d-flex align-items-center">
        <span className="ml-3 mt-2">{item?.productid?.name}</span>
      </td>
      <td>{item?.rate}</td>
      <td>{item?.quantity}</td>
      <td>{item?.pumpid?.name}</td>
      <td>
        <img onClick={() => setEditShow(true)} src={edit} alt="edit" />
      </td>
      <td>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button  {...bindTrigger(popupState)}>
                <img src={moreIcon} alt="" />
              </Button>
              <Menu {...bindMenu(popupState)}>

              <MenuItem
                  onClick={() => {
                    setDetailShow(true);
                  }}
                >Details</MenuItem>
                <MenuItem
                  onClick={() => {
                    deleteUser(item);
                  }}
                >Delete</MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </td>
      {editShow ? <Edit fetchData={fetchData} setLoader={setLoader} item={item} show={editShow} setShow={setEditShow} /> : null}
      {detailShow ? <Detail item={item} show={detailShow} setShow={setDetailShow} /> : null}
    </tr>
  );
}
