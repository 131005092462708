import React, { useState } from "react";
import rightArrow from "../../images/right-arrow.svg";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import edit from "../../images/edit.svg";
// import EditModal from "./edit";
import moreIcon from '../../images/moreIcon.svg'
import DetailModal from "./detailModal";
import EditModal from "./editModal";
import { useSelector } from "react-redux";
import api from '../../apis';
import swal from "sweetalert";

export default function StaffRow({ item, fetchData, setLoader }){
  const [editShow, setEditShow] = useState(false);
  const [detailShow, setDetailShow] = useState(false);

  const user = useSelector(state => state.authedUser.authedUser);

  const blockUser = async (popupState) => {
    try {
      if (user.type == "admin" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true);
        popupState.close();
        let data = item;
        data.status = !data.status;
        let response = await api.update_staff({user: {_id: item._id}, update: data});

        if (!response) {
          throw new Error("Error while updating status");
        }

        await fetchData();
        // alert("Status Update Successfull.");\
        swal({
          title: "STATUS UPDATED SUCCESSFULLY !",
          // text: "You clicked the button!",
          icon: "success",
        });
        setLoader(false);
      }
      else {
        swal({
          title: "You are blocked due to some reason contact admin",

          icon: "warning",
        });
      }

    } catch (e) {
      setLoader(false);
    }
  }

  const deleteUser = async (popupState) => {
    try {
      if (user.type == "admin" || (user.type == "pump" && user.canUseAll)) {
        setLoader(false);
        popupState.close();
        let data = item;
        console.log(data);
        const response = await api.delete_staff({_id: data._id});

        if (!response) {
          throw new Error("Error while deleting");
        }

        await fetchData();
        // alert("Deleted Successfull.");
        swal({
          title: "DELETED SUCCESSFULLY !",
          // text: "You clicked the button!",
          icon: "success",
        });
        setLoader(false);
      }
      else {
        swal({
          title: "You are blocked due to some reason contact admin",
          icon: "warning",
        });
      }
    } catch (e) {
      setLoader(false);
    }
  };

  return(
    <tr>
      <td scope="row">
        {item?.email}
      </td>
      <td scope="row">
        {item?.name}
      </td>
      <td scope="row">
        {item?.status ? 'Active' : 'Blocked'}
      </td>
      <td scope="row">
        {item?.address}
      </td>
      <td>
        <img onClick={() => setEditShow(true)} src={edit} alt="edit" />
      </td>
      <td>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button  {...bindTrigger(popupState)}>
                <img src={moreIcon} alt="" />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {popupState.close(); setDetailShow(true)}}>Details</MenuItem>
                <MenuItem onClick={() => blockUser(popupState)}>{item?.status ? 'Block' : 'Un-block'}</MenuItem>
                <MenuItem onClick={() => deleteUser(popupState)}>Delete</MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </td>
      {detailShow ? <DetailModal data={item} open={detailShow} setOpen={setDetailShow} /> : null}
      {editShow ? <EditModal fetchData={fetchData} defaultData={item} open={editShow} setOpen={setEditShow} /> : null}
    </tr>
  );
}