import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {App} from './App.jsx';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/store';
import { BrowserRouter } from 'react-router-dom';

const store = ConfigureStore();


ReactDOM.render(
  <React.StrictMode>
     <>
     <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
     </>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
