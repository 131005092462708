import React, { useEffect } from "react";
import { BrowserRouter, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactDOM from 'react-dom';


import { NavLink, Link } from "react-router-dom";
import $ from 'jquery';

import drivers from "../../../images/drivers.svg";
import financial from "../../../images/financial.svg";
import home from "../../../images/home.svg";
import load from "../../../images/load.svg";
import map from "../../../images/map.svg";
import setting from "../../../images/setting.svg";
import profile from "../../../images/profile.jpg";
import logo from "../../../images/logo.png";
import { useSelector } from "react-redux";


function Sidebar() {
  const authedUser = useSelector(state => state.authedUser);

  useEffect(() => {
    sidebarFunctionality()
  })

  const sidebarFunctionality = () => {
    /* eslint-disable */
    "use strict";
    $("#sidebarToggle, #sidebarToggleTop").on("click", function (e) {
      $("body").toggleClass("sidebar-toggled"), $(".sidebar").toggleClass("toggled"), $(".sidebar").hasClass("toggled") && $(".sidebar .collapse").collapse("hide");
    }),
      $(window).resize(function () {
        $(window).width() < 768 && $(".sidebar .collapse").collapse("hide"),
          $(window).width() < 480 && !$(".sidebar").hasClass("toggled") && ($("body").addClass("sidebar-toggled"), $(".sidebar").addClass("toggled"), $(".sidebar .collapse").collapse("hide"));
      }),
      $("body.fixed-nav .sidebar").on("mousewheel DOMMouseScroll wheel", function (e) {
        var o;
        768 < $(window).width() && ((o = (o = e.originalEvent).wheelDelta || -o.detail), (this.scrollTop += 30 * (o < 0 ? 1 : -1)), e.preventDefault());
      }),
      $(document).on("scroll", function () {
        100 < $(this).scrollTop() ? $(".scroll-to-top").fadeIn() : $(".scroll-to-top").fadeOut();
      });

  }


  return (
    <>

      {/* Sidebar */}
      <ul className="navbar-nav shadow sidebar sidebar-dark accordion zi-1 pb-100" id="accordionSidebar">
        {/* Sidebar - Brand */}
        <Link className="sidebar-brand d-flex align-items-center justify-content-center mt-2" to="/">

          <div className="sidebar-brand-text">
            <img src={logo} className="w-100" alt="apollo" />
          </div>
        </Link>

        {/* sidebar links */}
        <li className="nav-item my-1 mt-5">
          <NavLink className="nav-link sidebar-Link fw-500" to="/dashboard">
            <img className="" src={home} alt="" />
            <span className="">Dashboard</span></NavLink>
        </li>
        {/* Nav Item - Pages Collapse Menu */}
        {(authedUser.authedUser.type !== "company" &&
          authedUser.authedUser.type !== "pump" &&
          authedUser.authedUser.type !== "vehicle" &&
          authedUser.authedUser.type !== "staff" &&
          authedUser.authedUser.canUseAll) ? (
          <li className="nav-item my-1">
            <a className="nav-link sidebar-Link fw-500 collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
              <img className="" src={load} alt="" />
              <span className="">Pump</span>
            </a>
            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <NavLink className="nav-link sidebar-Link fw-500" to="/pump"> <span className="downCircle"></span>Pump</NavLink>
                <NavLink className="nav-link sidebar-Link fw-500" to="/pump/detail"> <span className="downCircle"></span>Detail</NavLink>
              </div>
            </div>
          </li>
        ) : (<></>)}
        {(authedUser.authedUser.type !== "staff" &&
          authedUser.authedUser.type !== "company" &&
          authedUser.authedUser.type !== "vehicle") ? (
          <li className="nav-item my-1 ">
            <NavLink className="nav-link sidebar-Link fw-500" to="/pump_payments">
              <img className="" src={drivers} alt="" />
              <span className="">Pump Payments</span>
            </NavLink>
          </li>
        ) : (<></>)}
        {(authedUser.authedUser.type !== "company" &&
          authedUser.authedUser.type !== "vehicle" &&
          authedUser.authedUser.type !== "staff") ? (
          <li className="nav-item my-1 ">
            <NavLink className="nav-link sidebar-Link fw-500" to="/Company">
              <img className="" src={drivers} alt="" />
              <span className="">Company</span>
            </NavLink>
          </li>
        ) : (<> </>)}
        {(authedUser.authedUser.type !== "vehicle" &&
          // authedUser.authedUser.type !== "pump" &&
          authedUser.authedUser.type !== "staff") ? (
          <li className="nav-item my-1">
            <NavLink className="nav-link sidebar-Link fw-500" to="/vehicle">
              <img className="" src={map} alt="map" />
              <span className="">vehicle</span>
            </NavLink>
          </li>
        ) : (<></>)}
        {(authedUser.authedUser.type !== "company" &&
          authedUser.authedUser.type !== "vehicle" &&
          authedUser.authedUser.type !== "staff") ? (
          <li className="nav-item my-1">
            <NavLink className="nav-link sidebar-Link fw-500" to="/staff">
              <img className="" src={map} alt="map" />
              <span className="">Staff</span>
            </NavLink>
          </li>
        ) : (<></>)}
        {(authedUser.authedUser.type !== "company" &&
          authedUser.authedUser.type !== "vehicle" &&
          authedUser.authedUser.type !== "staff") ? (
          <li className="nav-item my-1">
            <NavLink className="nav-link sidebar-Link fw-500" to="/product">
              <img className="" src={map} alt="map" />
              <span className="">Product</span>
            </NavLink>
          </li>
        ) : (<></>)}
        {(authedUser.authedUser.type !== "company" &&
          authedUser.authedUser.type !== "vehicle" &&
          authedUser.authedUser.type !== "staff") ? (
          <li className="nav-item my-1">
            <NavLink className="nav-link sidebar-Link fw-500" to="/inventory">
              <img className="" src={map} alt="map" />
              <span className="">Inventory</span>
            </NavLink>
          </li>
        ) : (<> </>)}
        {/* {() ? () : (<> </>)}*/}
        {(authedUser.authedUser.type !== "company" &&
          authedUser.authedUser.type !== "vehicle" &&
          authedUser.authedUser.type !== "staff") ? (
          <li className="nav-item my-1">
            <NavLink className="nav-link sidebar-Link fw-500" to="/create-hr">
              <img className="" src={map} alt="map" />
              <span className="">HR Record</span>
            </NavLink>
          </li>

        ) : (<> </>)}

        {(authedUser.authedUser.type === "staff" ||
          authedUser.authedUser.type === "admin" ||
          (authedUser.authedUser.type === "pump")) ? (
          <li className="nav-item my-1">
            <NavLink className="nav-link sidebar-Link fw-500" to="/attendance">
              <img className="" src={map} alt="map" />
              <span className="">Attendance</span>
            </NavLink>
          </li>
        ) : (<></>)}
        {(authedUser.authedUser.type === "staff" ||
          authedUser.authedUser.type === "admin" ||
          authedUser.authedUser.type === "pump") ? (
          <li className="nav-item my-1">
            <NavLink className="nav-link sidebar-Link fw-500" to="/payments">
              <img className="" src={map} alt="map" />
              <span className="">Payments</span>
            </NavLink>
          </li>

        ) : (<></>)}
        {authedUser.authedUser.type !== "staff" ? (
          <li className="nav-item my-1">
            <NavLink className="nav-link sidebar-Link fw-500" to="/transaction">
              <img className="" src={map} alt="map" />
              <span className="">Transaction</span></NavLink>
          </li>
        ) : (<></>)}
        {(authedUser.authedUser.type !== "staff") ? (
          <li className="nav-item my-1">
            <NavLink className="nav-link sidebar-Link fw-500" to="/billing">
              <img className="" src={map} alt="map" />
              <span className="">Billing</span>
            </NavLink>
          </li>
        ) : (<></>)}

        {(authedUser.authedUser.type == "pump") ? (
          <li className="nav-item my-1">
            <NavLink className="nav-link sidebar-Link fw-500" to="/accounting">
              <img className="" src={map} alt="map" />
              <span className="">Accounting</span>
            </NavLink>
          </li>
        ) : (<></>)}


      </ul>
      {/* End of Sidebar */}

    </>
  )

}
export default Sidebar