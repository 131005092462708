import React, { useState } from "react";
import SiteNav from "../siteNavbar/siteNavbar";
import modalImg from '../../../images/modalImg.png';
import { useNavigate, Navigate, NavLink } from "react-router-dom";
// import { withRouter, Redirect, Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../footer/footer";
import { cardHeaderClasses } from "@mui/material";
import api from "../../../apis";
import { loginUserReq } from "../../../redux/actions/authedUser";

export function LoginAdmin({ setIsLogin }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authedUser = useSelector(state => state.authedUser);

    function handleSubmit(e) {
        e.preventDefault();
        console.log(data.email, data.password, data.type);
        dispatch(loginUserReq(data.email, data.password, data.type))
        // setIsLogin(true)
        // history.push('/dashboard')
    }

    const [data, setData] = useState({
        email: "",
        password: "",
        type: "admin",
    });


    const handleEmailchange = (event) => {
        setData({ ...data, email: event.target.value });
    }

    const handlepasswordchange = (event) => {
        setData({ ...data, password: event.target.value });
    }

    const handletypechange = (event) => {
        setData({ ...data, type: event.target.value });
    }

    const dologin = async () => {
        try {
            const payload =
            {
                email: data.email,
                password: data.password,
                type: data.type,
            }
            console.log("login", payload);

            dispatch(loginUserReq(data.email, data.password, data.type))
                .then(r => {

                });

            // if (!response) {
            //     throw new Error("invalid")
            // }
            // console.log(response);
        }
        catch (e) {
            console.log(e);
            alert(e)
        }
    }
    if (Object.keys(authedUser.authedUser).length === 0) {

        return (
            <div className="loginPage">
                <SiteNav />
                <div className='contactModal' >
                    <div className="container">
                        <div className="row ai-center">
                            <div className="col-lg-7">
                                <div className="modalImg">
                                    <img className="img-fluid" src={modalImg} alt="pump" />
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="mainContent">
                                    <div className="heading text-center p-0">
                                        <h1>Login</h1>
                                        <p className="mb-4">Find out more about pumpify.</p>
                                    </div>
                                    <div className="row mx-0">
                                        <div className="col-md-12">
                                            <form
                                                onSubmit={(e) => handleSubmit(e)}
                                            >
                                                <div className="form-group mb-3">
                                                    <label htmlFor="Name">Email</label>
                                                    <input name="name" type="text" className="form-control theme-bg-color " id="exampleInputPassword1" placeholder="Your Email" onChange={handleEmailchange} />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="exampleInputEmail1">Password</label>
                                                    <input name="email" type="password" className="form-control theme-bg-color " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Your Password" onChange={handlepasswordchange} />
                                                    <NavLink className=" text-danger" to="/ForgotPassword"> Forgot Password?</NavLink>
                                                </div>
                                                <div className="text-center mt-4">
                                                    <button type="submit" className="btn sendBtn" onClick={dologin}>Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        )
    }
    else {
        return (
            <Navigate to="/dashboard" />
        );
    }
}