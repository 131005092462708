
import React from "react";
import './loader.css'
function loader() {
    return (
        <div class="globalModal">
            <div class="loader">
                {/* #4d53e0 */}
                <div class="spinner-border spinnercolor" role="status">
                    <span class="sr-only">Loading...</span>

                </div>
                <br />
                <h5>loading...</h5>
            </div>
        </div>
    );
}

export default loader;
