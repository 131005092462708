import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import edit from "../../images/edit.svg";
import moreIcon from '../../images/moreIcon.svg'
import Detail from './detail';
import Edit from './edit';
import ResolveModal from './resolve';
import api from '../../apis';
import swal from 'sweetalert';
import Loader from '../loader';
import RequestModal from './resolverequest';

export default function Row({ item, fetchData }){

  const [resolveShow, setResolveShow] = useState(false);
  const [requestShow, setRequestShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [detailShow, setDetailShow] = useState(false);
  const user = useSelector(state => state.authedUser.authedUser);
  const [loader, setLoader] = useState(false);

  const unresolveItem = async () => {
    try {
      setLoader(true);
      let response = await api.UnResolveAPIHR({
        _id: item._id,
      });

      if (!response) {
        throw new Error("Error while updating status");
      }

      await fetchData();
      //alert("Status Update Successfull.");
      swal({
        title: "STATUS UPDATED SUCCESSFULLY !",
        // text: "You clicked the button!",
        icon: "success",
      });
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  }

  const changeStatus = async () => {
    try {
      setLoader(true);
      let response = await api.hr_approve({
        _id: item._id,
        isApproved: item.isApproved,
      });
      console.log(response);
      if (!response) {
        throw new Error("Error while updating status");
      }

      await fetchData();
      //alert("Status Update Successfull.");
      swal({
        title: "STATUS UPDATED SUCCESSFULLY !",
        // text: "You clicked the button!",
        icon: "success",
      });
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  }
  
  const cancelreq = async () => {
    try {
      setLoader(true);
      let response = await api.Cancelreq({
        _id: item._id,
      });

      if (!response) {
        throw new Error("Error while updating status");
      }

      await fetchData();
      //alert("Status Update Successfull.");
      swal({
        title: "STATUS UPDATED SUCCESSFULLY !",
        // text: "You clicked the button!",
        icon: "success",
      });
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  }

  return(
    <tr>
      <td scope="row">
        {item?.cnic}
      </td>
      <td>
        {item?.name}
      </td>
      <td>
        {item?.description}
      </td>
      <td>
        {item.pump?.name}
      </td>
      {user?.type === "admin" && (
        <td>{item?.requestresolve ? "yes" : "no"}</td>
      )}
      <td>
        {item?.requestDescription}
      </td>
      <td>
        {item?.resolved ? "Resolved" : "Not Resolved"}
      </td>
      <td>
        {item?.resolvedDescription && item?.resolved ? item?.resolvedDescription : "N/A"}
      </td>
      {user?.type === "admin" && (
        <td>
          {item?.isApproved
            ? "Approved"
            : "Not Approved"}
        </td>
      )}
      <td>
        <img onClick={() => setEditShow(true)} src={edit} alt="edit" />
      </td>
      <td>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button  {...bindTrigger(popupState)}>
                <img src={moreIcon} alt="" />
              </Button>
              <Menu {...bindMenu(popupState)}>
                {item?.requestresolve ? (user.type === "admin" ? <MenuItem onClick={() => {popupState.close(); if (item?.resolved){unresolveItem()}else{setResolveShow(true)}}}>{item?.resolved ? "Un resolve" : "Resolve"}</MenuItem> : null) : null}
                <MenuItem onClick={() => {popupState.close(); setDetailShow(true)}}>Details</MenuItem>
                {user.type === "admin" ? <MenuItem onClick={() => {popupState.close(); changeStatus()}}>{item?.isApproved ? "Un-Approve" : "Approve"}</MenuItem> : null}
                {!item?.resolved ? item?.isApproved ? ((user.type === "pump" || user.type === "admin") ? <MenuItem onClick={() => {popupState.close(); item?.requestresolve ? cancelreq() : setRequestShow(true);}}>{item?.requestresolve ? "cancel request" : "request resolve"}</MenuItem> : null) : null : null}
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </td>
      {editShow ? <Edit fetchData={fetchData} setLoader={setLoader} item={item} show={editShow} setShow={setEditShow} /> : null}
      {detailShow ? <Detail item={item} show={detailShow} setShow={setDetailShow} /> : null}
      {requestShow ? <RequestModal fetchData={fetchData} show={requestShow} item={item} setShow={setRequestShow} /> : null}
      {resolveShow ? <ResolveModal fetchData={fetchData} show={resolveShow} item={item} setShow={setResolveShow} /> : null}
      {loader ? <Loader /> : null}
    </tr>
  );
}