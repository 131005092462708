import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Dashboard } from '../component/dashboard/dashboard';
import Drivers from '../component/company/company';
import Loads from '../component/pump/pump';
import AssignNewLoad from '../component/assignNewLoad/AssignNewLoad';
import MapVeiw from '../component/MapVeiw/MapView';
import Staff from '../component/staff/staff';
import Product from '../component/product/product';
import Inventory from '../component/inventory/inventory';
import HRrecord from '../component/HR records/createhr';
import Payments from '../component/Payments/payments';
import Attendance from '../component/Attendance/attendance';
import Transaction from '../component/transaction/transaction';
import Billing from '../component/billing/billing';
import Accounting from '../component/accounting/accounting';
import Pump_payments from '../component/pump_payments/pump_payments';

function AppRouter() {
    console.log("mcccccc");
    return (
        <Routes>
            {/* <Route exact path='/'  element={<Navigate to="/dashboard" />} /> */}
            <Route exact path='/dashboard' element={<Dashboard />} />
            <Route exact path='/pump_payments' element={<Pump_payments />} />
            <Route path='/pump' element={<Loads />} />
            <Route path='/Company' element={<Drivers />} />
            <Route path='/assignNewLoad' element={<AssignNewLoad />} />
            <Route path='/vehicle' element={<MapVeiw />} />
            <Route path='/staff' element={<Staff />} />
            <Route path='/product' element={<Product />} />
            <Route path='/inventory' element={<Inventory />} />
            <Route path='/create-hr' element={<HRrecord />} />
            <Route path='/attendance' element={<Attendance />} />
            <Route path='/payments' element={<Payments />} />
            <Route path='/transaction' element={<Transaction />} />
            <Route path='/billing' element={<Billing />} />
            <Route path='/accounting' element={<Accounting />} />
        </Routes>
    )
}

export default AppRouter