import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import api from '../../apis';

export default function Edit({ item, show, setShow, fetchData, setLoader }){
  const user = useSelector(state => state.authedUser.authedUser);
  const [data, setData] = useState({
    rate: item?.rate,
    quantity: item?.quantity
  })
  const validator = () => {
    if (!data.rate) {
      return "Rate is required";
    }
    if (data.rate && isNaN(data.rate)) {
      return "Rate is not correctly formatted";
    }

    return false;
  };
  
  const handleRateChange = (event) => {
    setData({ ...data, rate: event.target.value });
  };

  const handleQuantityChange = (event) => {
    setData({ ...data, quantity: event.target.value });
  }

  const saveDetails = async () => {
    try {
      if (user.type == "admin" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true)
        if (validator()) {
          throw new Error(validator());
        } else {
          if (localStorage.getItem("token")) {
            data.quantity = parseFloat(data.quantity);
            data.rate = parseFloat(data.rate);

            let response = await api.update_inventory({_id: item._id, ...data});

            console.log(response);

            if (!response) {
              throw new Error("Error while updating product");
            }
            await fetchData();
            setShow(false);
            //alert("Product Update Successfull.");
            swal({
              title: "PRODUCT UPDATE SUCCESSFULLY !",
              // text: "You clicked the button!",
              icon: "success",
            });
          }
        }
        setLoader(false)

      }
      else {
        swal({
          title: "You are blocked due to some reason contact admin",

          icon: "warning",
        });

      }
    } catch (e) {
      console.log(e);
      // alert(e);
      swal({
        title: e,

        icon: "warning",
      });
      setLoader(false)
    }
  }
  return(
    <Modal show={show} onHide={() => setShow(false)} centered={true}>
        <Modal.Body>
          <div className="modalForm">
            <h3 className=''>Edit Inventory</h3>

            <div className="form-group">
              <label>Rate</label>
              <input type="number" value={data?.rate} className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Rate" onChange={handleRateChange} />
            </div>
            <div className="form-group">
              <label>Quantity</label>
              <input type="number" value={data?.quantity} className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Quantity" onChange={handleQuantityChange} />
            </div>

            <div className='text-center subBtn'>
              <button class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10" onClick={saveDetails}>Submit</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
  );
}