import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux";
import swal from "sweetalert";
import api from "../../apis";
import BankDetails from './bankdetails'
import edit from "../../images/delete.svg";
import './accounting.css'
import Loader from '../loader';
// import swal from "sweetalert";

export default function Products({ Editproductshow, EditsynchandleClose, EditsetProductshow }) {

  const user = useSelector(state => state.authedUser.authedUser);
  const [bankshow, setBankshow] = useState(false)
  const [inventory, setInventory] = useState([])

  useEffect(() => {
    fetchSyncProducts();
  }, [])


  const [loader, setLoader] = useState(false);
  const [data, setData] = React.useState({
    name: "",
    unit: "ltr",
    sellingrate: "",
  });


  const validator = () => {

    if (!data.name) {
      return "Name is required";
    }
    if (!data.unit) {
      return "Unit is required";
    }
    if (!data.sellingrate) {
      return "Selling rate is required";
    }

    return false;
  };


  const handleNameChange = (event) => {
    setData({ ...data, name: event.target.value });
  };

  const handleUnitChange = (event) => {
    console.log("--")
    setData({ ...data, unit: event.target.value });
  };

  const handlesellingChange = (event) => {
    setData({ ...data, sellingrate: parseFloat(event.target.value) });
  };

  const fetchSyncProducts = async () => {
    try {
      const payload = {
        pump: user?._id
      }
      const response = await api.syncproducts(payload)
      console.log("newproducts", response);
      setInventory(response.message);
    }
    catch (e) {
      console.log(e);
    }
  }

  const addFunc = async () => {
    try {
      if (user.type == "admin" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true)
        if (validator()) {
          throw new Error(validator());
        } else {
          if (localStorage.getItem("token")) {
            // data.currentQuantity = data.quantity;
            data.rate = parseFloat(data.sellingrate)

            const payload =
            {
              pump: user._id,
              name: data.name,
              unit: data.unit,
              // image: data.image,
              sellingrate: data.sellingrate,
            }
            console.log("product", payload);

            const response = await api.createproducts(payload);

            if (!response) {
              throw new Error("Error while adding new product");
            }
            setData({});
            productmodule();
            swal({
              title: "PRODUCT ADDED SUCCESSFULLY !",
              icon: "success",
            });
            // fetchproducts();
          }
        }
        setLoader(false)
      }
      else {
        swal({
          title: "You are blocked due to some reason contact admin",
          icon: "warning",
        });
      }
    } catch (e) {
      console.log(e);
      swal({
        title: e,
        icon: "warning",
      });
      setLoader(false)
    }
  };


  const bankhandleClose = () => {
    setBankshow(false);
  }


  const [addproductshow, setAddProductshow] = useState(false)

  const producthandleClose = () => {
    setAddProductshow(false);
  }

  const addproductmodule = () => {
    EditsynchandleClose()
    setAddProductshow(true)
  }
  // const [productshow, setProductshow] = useState(false)

  const productmodule = () => {
    EditsetProductshow(true)
    producthandleClose();
  }





  return (
    <>
      <Modal show={Editproductshow} onHide={EditsynchandleClose} centered={true}>
        <Modal.Body>
          <div className="modalForm br-10 d-flex methodcontainer" >
            <h3 className=''>Sync Products</h3>

            <button class=" w-25 btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 ms-auto"
              onClick={() => addproductmodule()}
            >Add</button>

            {typeof inventory !== "string" &&
              inventory.map((item) =>
              (
                <div className='productcontainer theme-bg-color'>
                  {/* <img className='delete' src={edit} alt="" /> */}
                  <br />
                  <div>
                    <h3>{item?.name}</h3>
                    <p>QTY: {item?.tottalinventory}</p>
                  </div>
                </div>
              ))}


            <button class="w-25 mt-5 btn border-transparent loadBtnColor loadBtnBg justify-content-center font-weight-bold br10"
            // onClick={() => bankmodule()}
            >Save</button>
          </div>

        </Modal.Body>
      </Modal>
      <Modal show={addproductshow} onHide={producthandleClose} centered={true}>
        <Modal.Body>
          <div className="modalForm">
            <h3 className=''>Add New Product</h3>
            <div className="form-group">
              <label>Name</label>
              <input type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name"
                onChange={handleNameChange}
              />
            </div>
            <div className="form-group">
              <label>Selling Rate</label>
              <input type="text" className="form-control theme-bg-color border-0 small" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Selling Rate"
                onChange={handlesellingChange}
              />
            </div>
            <div className="form-group">
              <label>select unit</label>
              <select className="form-control theme-bg-color border-0 " name="" id=""
                onChange={handleUnitChange}
              >
                <option value="" disabled>Select Unit</option>
                <option value="ltr">Liter</option>
                <option value="kg">Kilograms</option>
              </select>
            </div>
            <div className='text-center subBtn'>
              <button class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10" onClick={addFunc} >Submit</button>
            </div>

            {/* <div className='text-center subBtn'>
              <button onClick={() => productmodule()} class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold px-5 br10" >Back to Products</button>
            </div> */}

          </div>
        </Modal.Body>
      </Modal>

      <BankDetails bankshow={bankshow} bankhandleClose={bankhandleClose} />

      {loader && <Loader />}
    </>
  );
}