import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import rightArrow from "../../images/right-arrow.svg";


import swal from "sweetalert";
import { useSelector } from "react-redux";
import api from '../../apis';
import { v4 as uuidv4 } from "uuid";
import Loader from '../loader';



function Pump() {

  const authedUser = useSelector(state => state.authedUser);
  const user = authedUser.authedUser;
  const [show, setShow] = useState(false);
  const [editshow, seteditShow] = useState(false);
  const [staff, setStaff] = useState([]);
  const [loader, setLoader] = useState(false);
  const edithandleClose = () => seteditShow(false);
  const [sum, setSum] = useState(0);
  const [data, setData] = useState([]);
  const handleShow = () => setShow(true);
  const [trans, setTransData] = useState([]);
  const [isTrans, setTrans] = useState(false);
  const [open, setOpen] = useState(false);
  const [attendance, setAttendance] = useState({});
  const [selectedStaff, setSelecetedStaff] = useState(false);

  useEffect(() => {
    if (user.type === "pump" || user.type === "admin") {
      fetchStaff();
    }
    if (user.type === "staff") {
      // setSelecetedStaff(user._id);
    }

    // fetchAttendance();
    fetchAllAttendance();
  }, []);

  useEffect(() => {
    if (selectedStaff) {
      fetchAttendance();
    }
  }, [selectedStaff]);




  const setSelecetedStaffChange = (e) => {
    setSelecetedStaff(e.target.value);
  };

  const fetchStaff = async () => {
    try {
      setLoader(true);
      let res;

      if (user.type === "pump") {
        // res = await get_staff({ pump: user._id, type: "staff" });
        res = await api.getstaff({ pump: user._id, type: "staff" })
      }
      if (user.type === "admin") {
        // res = await get_staff({ type: "staff" });
        res = await api.getstaff({ type: "staff" })
      }
      console.log("staff", res);
      setStaff(res.message);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };


  const fetchAllAttendance = async () => {
    try {
      setLoader(true);
      let response;
      if (user.type === "staff") {
        response = await api.getallstaffattendance({ user: user._id })
      }
      if (user.type === "admin") {
        const data = {}
        response = await api.getallattendance({});
      }
      if (user.type === "pump") {
        response = await api.getallattendance({});
        let result;
        if (user.type === "pump") {
          result = response.filter((item) => item.user.pump === user._id);
        } else {
          result = response.filter((item) => item.user.pump === user.pump);
        }
        response = result;
      }
      if (response.length) {
        setData(response);
      }
      console.log("RESR", response);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const dateConvert = (date) => {
    return new Date(date).toLocaleString("en-GB", { hour12: true });
  };

  const fetchTrans = async (param1) => {

    try {
      // setLoader(true);
      let param = {
        cashierId: param1.user._id,
      };
      const response = await api.readtransaction(param)
      console.log("transres", response);
      const res = response.message.filter(
        (item) => item.date >= param1.clockIn && item.date <= param1.clockOut
      );
      console.log("hellosum", res);
      var sum = 0;
      for (var i = 0; i < res.length; i++) {
        sum += parseInt(res[i].total);
      }
      console.log("hellosum", sum);
      setSum(sum)
      setTransData(res);
      // setLoader(false);
    } catch (e) {
      console.log(e);
      // setLoader(false);
    }
  };


  const edithandleShow = async (item) => {

    try {
      seteditShow(true);
      if (user.type == "admin" || user.type == "staff" || (user.type == "pump" && user.canUseAll)) {

        // setLoader(true);

        let param;
        if (!item.clockOut) {
          param = {
            ...item,
            clockOut: new Date().toISOString(),
          };
        } else {
          param = {
            ...item,
          };
        }

        console.log("modalparams", param);
        await fetchTrans(param);
        console.log("ffff", fetchTrans(param));


        // setLoader(false);
      }
      else {
        swal({
          title: "You are blocked due to some reason contact admin",
          icon: "warning",
        });
      }
    }
    catch (e) {
      console.log(e);
      // setLoader(false);
    }
  }

  const createAttendanceF = async (e) => {
    try {
      if (user.type == "admin" || user.type == "staff" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true);
        let response;
        let att = await fetchAttendance();
        if (att) {
          throw new Error("already clocked in");
        }
        if (user.type === "pump" || user.type === "admin") {
          response = await api.createattendance({ _id: selectedStaff });
        } else {
          response = await api.createattendance({ _id: user._id });
        }
        fetchAttendance();
        fetchAllAttendance();
        console.log("rs", response);
        swal({
          title: "CLOCKED IN !",
          icon: "success",
        });
        setLoader(false);
      }
      else {
        swal({
          title: "You are blocked due to some reason contact admin",
          icon: "warning",
        });
      }
    } catch (e) {
      swal({
        title: e,

        icon: "warning",
      });
      setLoader(false);
    }
  };

  const fetchAttendance = async () => {
    try {
      setLoader(true);
      let response;
      if (user.type === "pump" || user.type === "admin") {
        response = await api.getattendance({ _id: selectedStaff })
      } else {
        response = await api.getattendance({ _id: user._id })
      }
      if (!response) {

        setOpen(false);
        setAttendance(response);
        setLoader(false);
        return false;
      } else {
        setOpen(true);
        setAttendance(response);
        setLoader(false);
        return true;
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };


  const updateAttendanceF = async (e) => {
    try {
      if (user.type == "admin" || user.type == "staff" || (user.type == "pump" && user.canUseAll)) {
        setLoader(true);
        let response;
        if (user.type === "pump" || user.type === "admin") {

          response = await api.updateattendance({ _id: selectedStaff });

        } else {

          response = api.updateattendance({ _id: user._id });

        }
        fetchAttendance();
        fetchAllAttendance();
        console.log("rsee", response);
        swal({
          title: "CLOCKED OUT !",
          icon: "success",
        });
        setLoader(false);
      }
      else {
        swal({
          title: "You are blocked due to some reason contact admin",
          icon: "warning",
        });
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };



  const itemsperpages = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / itemsperpages);

  const itemsInCurrentPage = data.slice((currentPage - 1) * itemsperpages, currentPage * itemsperpages);


  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col ">
            <div className=" card sortby-box br-10 border-transparent">
              {/* Card Header - Dropdown */}
              <div className="form-group col-lg-8">
                <label>Staff</label>
                <select onChange={setSelecetedStaffChange} className="form-control theme-bg-color border-0 " name="" id="">
                  <option value="" disabled selected>Select Staff</option>
                  {typeof staff !== "string" &&
                    staff.map((item) =>
                    (
                      <option value={item._id}>
                        {item.name} {item.email ? `(${item.email})` : ''}
                      </option>
                    ))}
                </select>
              </div>
              <div className="card-header br-10  bg-white border-transparent py-3 d-flex flex-row align-items-center ">
                <a className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 fs-12 "
                  onClick={() => createAttendanceF()}
                  disabled={
                    open || (!selectedStaff.length && user.type !== "staff")
                  }
                  style={{opacity: (open || (!selectedStaff.length && user.type !== "staff")) ? 0.7 : 1}}
                > Clock In</a>

                <a className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 fs-12 ml-5"
                  onClick={() => updateAttendanceF()}
                  disabled={
                    !open || (!selectedStaff.length && user.type !== "staff")
                  }
                  style={{opacity: (!open || (!selectedStaff.length && user.type !== "staff")) ? 0.7 : 1}}
                > Clock Out</a>
              </div>
            </div>
            {/* table */}
            <div className="card sortby-box br-10 border-transparent mt-3">
              <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 d-flex flex-column align-items-center justify-content-between">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">User</th>
                      <th scope="col">Email</th>
                      <th scope="col">Clock In</th>
                      <th scope="col">Clock Out</th>
                      <th scope="col">Transactions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      typeof itemsInCurrentPage !== "string" && itemsInCurrentPage
                        .map((item) => (
                          <tr key={uuidv4()}>
                            <td className="d-flex align-items-center">
                              <span className="ml-3 mt-2">
                                {item?.user?.name}
                              </span>
                            </td>
                            <td>{item?.user?.email}</td>
                            <td>{item?.user?.pump}</td>
                            <td>
                              {dateConvert(item?.clockIn.toString())}
                            </td>
                            <td>
                              {item && !item.clockOut
                                ? "Active Session"
                                : dateConvert(item?.clockOut.toString())}
                            </td>

                            <td>
                              <a onClick={() => edithandleShow(item)} className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold br10 fs-12 ml-5 w-55" > View Transactions </a>
                            </td>
                          </tr>
                        ))
                    }
                  </tbody>
                </table>

                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                      <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>
                        Prev
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li key={index + 1} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                        <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                      <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
              {/* <EditModal open={editModal} setOpen={closeModal} /> */}
              <Modal show={editshow} onHide={edithandleClose} centered={true}>
                <Modal.Body>
                  <div className="modalForm">
                    <h3 className=''>Transactions</h3>
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th className=" pl-4">Id</th>
                          <th className=" pl-4">Product</th>
                          <th className=" pl-4">Rate</th>
                          <th className=" pl-4">Qty</th>
                          <th className=" pl-4">Total</th>

                        </tr>
                      </thead>
                      <tbody>

                        {
                          typeof trans !== "string" && trans
                            .map((item) => (
                              <tr key={uuidv4()}>
                                <td className="d-flex align-items-center">
                                  <span className="ml-3 mt-2">
                                    {item?.genTxId}
                                  </span>
                                </td>
                                <td>{item?.productId.name}</td>
                                <td>{item?.rate.toFixed(2)}</td>
                                <td>{item?.quantity.toFixed(2)}</td>
                                <td>{item?.total.toFixed(2)}</td>
                              </tr>
                            ))
                        }

                      </tbody>
                    </table>
                    <div className="card-header br-10  bg-white border-transparent py-3 d-flex flex-row align-items-center ">
                      <div className='py-3 px-3 br-10 border-transparent loadBtnColor loadBtnBg font-weight-bold'>Grand Total :  {" " }
                      {
                        // calculate grand total and display
                        typeof trans !== "string" && trans.reduce((acc, item) => acc + item.total, 0).toFixed(2)
                      }
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </>
  )
}


export default Pump