import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { connect } from "react-redux";

function Details({ open, setOpen, data }) {

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Staff Details</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          value={data.name}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          value={data.email}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          margin="dense"
          label="Address"
          type="text"
          fullWidth
          value={data.address}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          margin="dense"
          label="Phone"
          type="text"
          fullWidth
          value={data.phone}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          margin="dense"
          label="Cnic"
          type="text"
          fullWidth
          value={data.cnic}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          margin="dense"
          label="Pump"
          type="text"
          fullWidth
          value={data?.pump?.email}
          InputProps={{
            readOnly: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Details;
