import { useRef, useState, useEffect } from "react";

import axios from "axios";

import api from "../../apis/index"
export default function Home({ setImgdata }) {
    const [image, setImage] = useState();
    const [link, setLink] = useState();

    const [preview, setPreview] = useState();
    const fileInputRef = useRef();

    const imgUp = async (e) => {


        try {
            const formData = new FormData();

            setPreview(e.target.files)

            formData.append("image", e.target.files[0], e.target.files[0].name);

            const uploadRes = await api.imgupload(formData)

            setImgdata({
                hrimageData: uploadRes[0],
                hrimageFormat: uploadRes[1],
            });
            setLink(`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${uploadRes[0]}.${uploadRes[1]}`)

        }
        catch (e) {
            console.log(e);
        }
    };

    return (
        <div style={{ textAlign: "-webkit-center" }}>
            <form>
                {link ? (
                    <img
                        src={link}
                        style={{
                            objectFit: "cover",
                            height: 200,
                            width: 200,
                            // borderRadius: 100,
                        }}
                        onClick={() => setImage(null)}
                    />
                ) : (
                    <button
                        style={{ border: "none", padding: 13 }}
                        onClick={(event) => {
                            event.preventDefault();
                            fileInputRef.current.click();
                        }}
                    >
                        Add Image
                    </button>
                )}
                <input
                    type="file"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    accept="image/*"
                    onChange={
                        imgUp
                    }
                />
            </form>
        </div>
    );
}
